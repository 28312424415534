import React, { useEffect, ReactNode, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useSignalRConnection } from '../contexts/SignalR/SignalRHook'
import { SignalRUrlType } from '../enums/SignalRUrlEnum'
import { getSection } from './arrayExtensions'
import { DashBoardCarInfoJsonResult } from '../models/dashBoardJsonResult'
import { AtrSection } from '../enums/AtrSectionEnum'
import { useAppContext } from '../contexts/AppContext'
import { PayedJsonResult } from '../models/DetailPayedResults/payedJsonResult'
import { getFormattedDate } from '../components/AtrOverviewCar'
import { ShowDateEnum } from '../enums/ShowDateEnum'

interface DataLayerWrapperProps {
    type: DataLayerType // Typ lokace, např. "souhrn"
    signalRUrlType: SignalRUrlType
    children: ReactNode // Obsah komponenty (vnořené komponenty)
}

export enum DataLayerType {
    Summary = 'souhrn',
    Payment = 'platba',
    Vypis = 'vypis',
    ZV = 'zv',
}

// Funkce pro hluboké porovnání objektů
const deepEqual = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

const cleanDataLayerObject = (dataLayerObject: any) => {
    if (!dataLayerObject) {
        return null // Pokud objekt není validní, vrátí null
    }
    const { 'gtm.uniqueEventId': _, ...cleanedObject } = dataLayerObject
    return cleanedObject
}

const DataLayerWrapper: React.FC<DataLayerWrapperProps> = ({ type, signalRUrlType, children }) => {
    const { bufferedData } = useSignalRConnection({
        url: signalRUrlType,
    })
    const [dataLayerState, setDataLayerState] = useState<any>(null)

    const appContext = useAppContext()

    useEffect(() => {
        const carInfoData = getSection<DashBoardCarInfoJsonResult>(bufferedData, AtrSection.CarInfo)
        const payedCarInfoData = getSection<PayedJsonResult>(bufferedData, AtrSection.CarInfo)
        const appModel = appContext.appModel.validateResponseModel
        const payedStateModel = appContext.payedStateModel

        const handleDataLayerUpdate = (dataLayerObject: any) => {
            const cleanedObject = cleanDataLayerObject(dataLayerObject)
            const cleanedState = cleanDataLayerObject(dataLayerState)

            if (cleanedObject && !deepEqual(cleanedState, cleanedObject)) {
                setDataLayerState(cleanedObject)
                TagManager.dataLayer({ dataLayer: dataLayerObject })
            }
        }

        if (carInfoData && type === DataLayerType.Summary) {
            handleDataLayerUpdate({
                event: 'vehicle_data',
                location: type,
                data: {
                    VIN: carInfoData.vin,
                    znacka: carInfoData.carMake,
                    model: carInfoData.carModel,
                },
            })
        }

        if (appModel.isValid && type === DataLayerType.Payment) {
            handleDataLayerUpdate({
                event: 'vehicle_data',
                location: type,
                data: {
                    VIN: appModel.carVin,
                    znacka: appModel.carMake,
                    model: appModel.carModel,
                },
            })
        }

        if (
            payedStateModel &&
            payedStateModel.viewId.length > 0 &&
            payedStateModel.dotazTypId === 1 &&
            payedCarInfoData &&
            payedCarInfoData.vehicleInfoControlResult &&
            type === DataLayerType.Vypis
        ) {
            const color = payedCarInfoData.vehicleInfoControlResult.vehicleColor?.find(
                x => x.isManufactureData,
            )

            handleDataLayerUpdate({
                event: 'vehicle_data',
                location: type,
                data: {
                    VIN: payedStateModel.carVin,
                    znacka: payedStateModel.carMake,
                    model: payedStateModel.carModel,
                    palivo: payedStateModel.fuel,
                    rok_vyroby: getFormattedDate(
                        ShowDateEnum.ShowYear,
                        payedStateModel.productYear,
                    ),
                    barva: color?.colorName,
                },
            })
        }
        if (payedStateModel && payedStateModel.viewId.length > 0 && type === DataLayerType.ZV) {
            handleDataLayerUpdate({
                event: 'vehicle_data',
                location: type,
                data: {
                    VIN: payedStateModel.carVin,
                    znacka: payedStateModel.carMake,
                    model: payedStateModel.carModel,
                    palivo: payedStateModel.fuel,
                    rok_vyroby: getFormattedDate(
                        ShowDateEnum.ShowYear,
                        payedStateModel.productYear,
                    ),
                },
            })
        }
    }, [bufferedData, appContext.storeAppModel, appContext.storePayedStateModel])

    return <>{children}</>
}

export default DataLayerWrapper
