import './index.styl'
import { ReactElement } from 'react'
import { Chart } from '../../../../components/Chart'
import { Coords, TimestampRange } from '../../../../components/Chart/getHightchartsOptions'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import {
    getCoordsFrom,
    getCoordsFromUnverified,
} from '../../../../models/DetailPayedResults/Tacho/tachosControlModels'
import { Translate } from '../../../../translations/CebiaTranslator'
import { ReferenceType } from '../../../../enums/ReferenceType'
import { ChartSimple } from '../../../../components/ChartSimple'

export const SectionTacho = ({
    sourceData,
    isPrint,
    referenceType,
}: DetailPayedProps): ReactElement => {
    const tran = new Translate()

    if ((sourceData?.tachoControlResult?.odometerValues?.length ?? 0) <= 0)
        return (
            <>
                <p>
                    {tran.GetText(
                        'V dostupných databázích nejsou k dispozici záznamy tachometru prověřovaného vozidla.',
                    )}
                </p>
                <p className="mb-20 md:mb-40">
                    {tran.GetHtml(
                        'Doporučujeme vám prověřit další možné zdroje záznamů tachometru, např. servisní knížku nebo dotazem na servisní historii v autorizovaném servisu. Při prohlídce vozidla pak věnujte pozornost opotřebení zejména těch částí, které mohou napovědět počet skutečně najetých kilometrů – více naleznete v <a href="https://www.cebia.cz/pruvodce/jak-zkontrolovat-stav-ojeteho-auta" data-link-enabled="true" data-link-href="https://www.cebia.cz/pruvodce/jak-zkontrolovat-stav-ojeteho-auta" target="_blank" class="Link Link--lightBlue">našem průvodci.</a>',
                    )}
                </p>
            </>
        )

    return (
        <>
            <p className="text-justify">
                {tran.GetText(
                    'Přehled vývoje stavu tachometru vozidla obsahuje hodnoty získané z dostupných informačních systémů spolupracujících partnerů. Dle vývoje grafu můžete sami posoudit, zda existuje podezření na manipulaci se stavem tachometru či nikoliv.',
                )}
            </p>
            <p className="mb-20 text-justify">
                <span className="font-bold">{tran.GetText('Doporučení')}:</span>
            </p>
            <p className="text-justify">
                {tran.GetText(
                    'V systému nemusí být k dispozici všechny záznamy stavů tachometru z historie provozu vozidla.',
                )}
            </p>
            <p className="text-justify">
                {tran.GetHtml(
                    'Pokud u vozidla není průběžně zaznamenaná celá historie kilometrového nájezdu v čase, <b>doporučujeme</b> zkontrolovat záznamy tachometru také v servisní knize, nebo vozidlo nechat zkontrolovat v autorizovaném autoservisu. Tento postup doporučujeme především u vozidel, kde je mezi stavy tachometru časový úsek delší než dva roky.',
                )}
            </p>
            <div className="mb-30">
                {referenceType && referenceType === ReferenceType.SmartCode ? (
                    <ChartSimple
                        data={getCoordsFrom(sourceData?.tachoControlResult) ?? Array<Coords>(0)}
                        unverifiedRanges={
                            getCoordsFromUnverified(sourceData?.tachoControlResult) ??
                            Array<TimestampRange>(0)
                        }
                        options={{}}
                        chartOptions={{ animations: isPrint ? false : true, isPrint: isPrint }}
                        maxValue={sourceData?.tachoControlResult?.maxValue ?? 0}
                    ></ChartSimple>
                ) : (
                    <Chart
                        data={getCoordsFrom(sourceData?.tachoControlResult) ?? Array<Coords>(0)}
                        unverifiedRanges={
                            getCoordsFromUnverified(sourceData?.tachoControlResult) ??
                            Array<TimestampRange>(0)
                        }
                        options={{}}
                        chartOptions={{ animations: isPrint ? false : true, isPrint: isPrint }}
                    />
                )}
            </div>
        </>
    )
}
