import { LanguageType } from '../enums/LanguageType'
import { RequestOptionsModel } from '../models/requestOptionsModel'

export function RequestOptionsValidator(value: RequestOptionsModel[], countryId: LanguageType) {
    if (
        countryId === LanguageType.RO ||
        countryId === LanguageType.IT ||
        countryId === LanguageType.NL
    ) {
        let isValid = false
        if (value && value?.length > 0) {
            if (value.find(x => x.isValid ?? false)) {
                isValid = true
            }
        }

        return isValid
    }
    return true
}

export function IcValidator(value: string | undefined, countryId: LanguageType) {
    if (countryId !== LanguageType.CZ && countryId !== LanguageType.SK) return true
    // if (countryId === LanguageType.RO) return CUIValidator(value)
    let isValid = true
    if (value && value?.length > 0) {
        const tmpValue = value.replace(/ /g, '').replace('/', '')
        const reg = new RegExp('^\\d+$')

        if (tmpValue.length > 3 && tmpValue.length < 9 && reg.test(tmpValue)) {
            let nCrc = 0
            for (let i = tmpValue.length - 2; i >= 0; i--) {
                nCrc += parseInt(tmpValue[i]) * (tmpValue.length - i)
            }
            nCrc = 11 - (nCrc % 11)

            if (nCrc === 10) nCrc = 0
            if (nCrc === 11) nCrc = 1

            if (parseInt(tmpValue[tmpValue.length - 1]) != nCrc) isValid = false
        } else {
            isValid = false
        }
    }

    return isValid
}

export function CUIValidator(value: string | undefined) {
    if (value && value?.length > 0) {
        if (!Number.isInteger(Number(value))) return false
        let cui = value ?? ''
        if (cui.length < 4 || cui.length > 10) return false
        const controlNumber = parseInt(cui.substring(cui.length - 1))
        cui = cui.substring(0, cui.length - 1)
        while (cui.length != 9) {
            cui = '0' + cui
        }
        let sum = 0
        let i = cui.length
        const testKey = '753217532'
        while (i--) {
            sum = sum + parseInt(cui.charAt(i)) * parseInt(testKey.charAt(i))
        }
        let calculatedControlNumber = (sum * 10) % 11
        if (calculatedControlNumber === 10) {
            calculatedControlNumber = 0
        }
        return controlNumber === calculatedControlNumber
    }
    return true
}

export function RcValidator(value: string | undefined) {
    let isValid = true

    if (value && value?.length > 0) {
        const tmpValue = value.replace(/ /g, '').replace('/', '').replace('\\', '')
        const reg = new RegExp('^\\d+$')
        isValid = tmpValue.length >= 9 && tmpValue.length < 11 && reg.test(tmpValue)

        if (isValid) {
            const is1953 = tmpValue.length === 9

            let year = parseInt(tmpValue.substring(0, 2))
            let month = parseInt(tmpValue.substring(2, 4))
            let day = parseInt(tmpValue.substring(4, 6))

            if (is1953 && year > 53) isValid = false

            if (
                isValid &&
                !is1953 &&
                (parseInt(tmpValue.substring(0, 9)) % 11) % 10 !==
                    parseInt(tmpValue.substring(9, 10))
            ) {
                isValid = false
            }

            if (isValid) {
                year +=
                    (is1953 && year < 54) || (!is1953 && year > new Date().getFullYear() % 100)
                        ? 1900
                        : 2000

                if (month > 70 && month < 82 && year > 2003) month -= 70
                else if (month > 50 && month < 63) month -= 50
                else if (month > 20 && month < 33 && year > 2003) month -= 20

                if (day > 50) day -= 50

                if (
                    month < 1 ||
                    month > 12 ||
                    year < 0 ||
                    year > 2100 ||
                    day <= 0 ||
                    day > new Date(year, month, 0).getDate()
                )
                    isValid = false
            }
        }
    }

    return isValid
}
