import { ErrorMessage, Form, Formik } from 'formik'
import { Fragment, ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { IcValidator, RequestOptionsValidator } from '../../busLog/clientValidators'
import { getCountries } from '../../busLog/enums_gen'
import { getCouponInfo, reloadPayment, setPayment } from '../../busLog/paymentBL'
import { Container } from '../../components/Container'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { Modal, useModal } from '../../components/Modal'
import { PageHeader } from '../../components/PageHeader'
import { ScrollToError } from '../../components/scroller'
import { StripeAlert } from '../../components/StripeAlert'
import { Title } from '../../components/Title'
import { useAppContext } from '../../contexts/AppContext'
import { LanguageType } from '../../enums/LanguageType'
import { LoadType } from '../../enums/LoadTypeEnum'
import { ReferenceType } from '../../enums/ReferenceType'
import { PaymentReqModel } from '../../models/paymentReqModel'
import { Translate } from '../../translations/CebiaTranslator'
import { redirectDotazLang } from '../../utils/redirect'
import './index.styl'
import { SectionCustomerCz } from './sections/SectionCustomer/indexCz'
import { SectionPayMethod } from './sections/SectionPayMethod'
import { EmailRegex, PhoneRegExp } from '../../utils/validators'
import { SectionShape } from '../../components/SectionShape'
import { SectionPaymentOptions } from './sections/SectionPaymentOptions'
import { BubbleOpener } from '../../components/BubbleOpener'
import { Button } from '../../components/Button'
import { Checkbox } from '../../components/Checkbox'
import { Section } from '../../components/Section'
import { SectionNoContainerSeparator } from '../homepage/sections/SectionNoContainerSeparator'
import { SectionSeparator } from '../homepage/sections/SectionSeparator'
import {
    getPaymentPrice,
    getPaymentPriceSale,
    getPaymentPriceWithoutVat,
} from './sections/SectionHeader'
import { IsValidEnum } from '../../enums/IsValidEnum'
import { ValidationResponseModel } from '../../models/validationResponseModel'
import { DashBoardCarInfoJsonResult } from '../../models/dashBoardJsonResult'
import { AppModel } from '../../models/appModel'

export const PagePayment = (): ReactElement => {
    const { open: openPersonaly } = useModal('paymentPersonaly')
    const appContext = useAppContext()
    const { id } = useParams()
    const navigate = useNavigate()
    const [paymentReq, setPaymentReq] = useState<PaymentReqModel>(
        new PaymentReqModel(id!, appContext.appModel),
    )
    const { open: openc } = useModal('paymentSaleCoupon')
    const {
        open: openDiscount,
        close: closeDiscount,
        active: isActive,
    } = useModal('paymentDiscount')
    const [couponValue, setCouponValue] = useState(paymentReq.saleCoupon)
    const { couponData, couponRefetch, couponError } = getCouponInfo(paymentReq)
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const tran = new Translate()
    const countries = getCountries(tran)

    const onDiscount = (isSale: boolean) => {
        if ((paymentReq?.viewId?.length ?? 0) > 0) {
            const payment = { ...paymentReq }
            //pokud je sale, tak se jedna o zruseni, sleva vsak muze byt už ze serveru, ale tam neni saleCoupon
            if (isSale && paymentReq.saleCoupon.length > 0) {
                setCouponValue('')
                payment.saleCoupon = paymentReq.saleCoupon = ''
                payment.discnoutCaption = paymentReq.discnoutCaption = undefined
                payment.isSale = paymentReq.isSale = false
            } else {
                payment.isSale = paymentReq.isSale = true
                payment.saleCoupon = paymentReq.saleCoupon = couponValue
                setCouponValue(couponValue)
            }
            setPaymentReq(payment)
        }
    }

    useEffect(() => {
        if (couponError) {
            setMessage(tran.GetText('Kód není platný!'))
            return
        } else if (couponData && couponData.isValid === IsValidEnum.NotValid) {
            const payment = { ...paymentReq }
            if (!payment.reloaded && message.length <= 0) {
                let valueOfCoupon = couponValue
                setMessage(couponData?.message ?? '')
                if (couponData.isValid) {
                    setCouponValue(paymentReq.saleCoupon)
                    payment.saleCoupon = paymentReq.saleCoupon
                }

                if (paymentReq.discnoutCaption?.length ?? 0 > 0) {
                    payment.discnoutCaption = paymentReq.discnoutCaption
                    // pokud jdu s kampane nastavim saleCoupon at to server správně pri refetch coupon spočítá
                    if (payment?.sale?.utm_campaign?.length ?? 0 > 0) {
                        payment.saleCoupon = valueOfCoupon = payment.sale.utm_campaign ?? ''
                        payment.isSale = true
                        setCouponValue(payment.sale.utm_campaign ?? '')
                        setPaymentReq(payment)
                        setMessage('')
                        return
                    }
                } else {
                    payment.saleCoupon = ''
                    setCouponValue('')
                    payment.discnoutCaption = paymentReq.discnoutCaption = undefined
                }
                payment.isSale = paymentReq.isSale = false
                if (payment.sale) payment.sale.b_discount = false
                if (couponData.totalPrice > 0) payment.price = couponData.totalPrice
                if (couponData.totalPrice > 0) payment.standardPrice = couponData.standardPrice
                if (couponData.isValid) {
                    payment.saleCoupon = valueOfCoupon
                }
                if (couponData.totalPrice > 0)
                    payment.standardPriceWithoutVat = couponData.standardPriceWithoutVat
                if (couponData.totalPrice > 0)
                    payment.priceWithoutVat = couponData.totalPriceWithoutVat
            } else {
                payment.isSale = paymentReq.sale.b_discount ?? false
                payment.reloaded = false
                if (payment.isSale) {
                    payment.saleCoupon = paymentReq.saleCoupon
                    setCouponValue(paymentReq.saleCoupon)
                } else {
                    payment.saleCoupon = ''
                    setCouponValue('')
                }
            }
            setPaymentReq(payment)
        } else {
            if (couponData && couponData.isValid == IsValidEnum.IsValid) {
                setMessage('')
                const payment = { ...paymentReq }
                payment.price = couponData.totalPrice
                payment.standardPrice = couponData.standardPrice
                payment.isSale = couponData.isValid == IsValidEnum.IsValid
                payment.discnoutCaption = payment.isSale
                    ? couponData.saleCouponDescription
                    : undefined
                payment.standardPriceWithoutVat = couponData.standardPriceWithoutVat
                payment.priceWithoutVat = couponData.totalPriceWithoutVat
                payment.reloaded = false
                closeDiscount()
                setPaymentReq(payment)
            }
        }
    }, [couponData?.message, couponData?.isValid, couponData?.totalPrice])

    useEffect(() => {
        if (paymentReq.price < 0 && id) {
            const loadType =
                (paymentReq?.make?.length ?? 0) <= 0 ? LoadType.WithImageData : LoadType.Normal

            reloadPayment(id!, paymentReq.validatedByVies, paymentReq.saleCoupon, loadType).then(
                payReq => {
                    if (payReq) {
                        if (payReq.isPayed > 0) {
                            if (payReq.isPayed === 3) {
                                navigate('/payed/detail/' + id)
                            } else {
                                setErrorMessage(
                                    tran.GetText('Platba neproběhla. Platbu prosím opakujte.'),
                                )
                            }
                        }
                        appContext.appModel.validateResponseModel.carMake = payReq.make
                        appContext.appModel.validateResponseModel.carModel = payReq.model
                        appContext.appModel.validateResponseModel.carVin = payReq.vin
                        appContext.appModel.validateResponseModel.isValid = payReq.isValid
                        appContext.storeAppModel(appContext.appModel, false)
                        //musi tady byt protozr server vraci image data jenom kdyz neexistuje v appContext.
                        if (loadType === LoadType.Normal) payReq.imageData = paymentReq.imageData
                        const countryPhonePrfx =
                            countries.find(x => x.id == payReq?.countryId)?.countryPhonePrefix ??
                            '+420'
                        if (
                            (payReq?.customerCountry === LanguageType.RO ||
                                payReq?.customerCountry === LanguageType.SK) &&
                            payReq.customerPhone.length === 0
                        ) {
                            payReq.customerPhone = countryPhonePrfx
                        }
                        const isInvoiceFilled =
                            payReq.invoiceCompanyName?.length > 0 ||
                            payReq.invoiceIc?.length > 0 ||
                            payReq.invoiceDic?.length > 0
                        payReq.invoiceOpened = isInvoiceFilled
                        payReq.reloaded = true
                        paymentReq.invoiceOpened = isInvoiceFilled
                        paymentReq.validatedByVies = payReq.validatedByVies
                        paymentReq.saleCoupon = payReq.saleCoupon
                        setPaymentReq(prevState => ({ ...prevState, ...payReq }))
                    } else setErrorMessage(tran.GetText('Chyba načtení dat.'))
                },
            )
        }
    }, [id])

    useEffect(() => {
        if (paymentReq !== undefined) paymentReq.customerCountry = paymentReq.countryId
        const countryPhonePrfx =
            countries.find(x => x.id == paymentReq?.countryId)?.countryPhonePrefix ?? '+420'
        if (
            (paymentReq?.customerCountry === LanguageType.RO ||
                paymentReq?.customerCountry === LanguageType.SK) &&
            paymentReq.customerPhone.length === 0
        ) {
            paymentReq.customerPhone = countryPhonePrfx
        }
        setPaymentReq(prevState => ({ ...prevState, ...paymentReq }))
    }, [paymentReq?.countryId])

    useEffect(() => {
        if (paymentReq?.sale?.utm_campaign) {
            paymentReq.saleCoupon = paymentReq?.sale?.utm_campaign
            setPaymentReq(prevState => ({ ...prevState, ...paymentReq }))
        }
    }, [paymentReq?.sale?.utm_campaign])

    useEffect(() => {
        if (paymentReq.countryId && paymentReq.vin) {
            redirectDotazLang(paymentReq.countryId)
        }
    }, [paymentReq?.countryId])

    useEffect(() => {
        setTimeout(() => {
            couponRefetch()
        }, 1)
    }, [paymentReq.validatedByVies, paymentReq.saleCoupon, paymentReq.invoiceOpened])

    const validationSchema = () => {
        return Yup.object().shape(
            {
                // ownerShipAgreement: Yup.boolean()
                //     .nullable()
                //     .when('countryId', {
                //         is: (countryId: LanguageType) => countryId && countryId === LanguageType.DE,
                //         then: Yup.boolean()
                //             .nullable()
                //             .oneOf([true], tran.GetText('Bez souhlasu nelze pokračovat')),
                //         otherwise: Yup.boolean().notRequired(),
                //     }),
                isGdpr: Yup.boolean()
                    .nullable()
                    .oneOf(
                        [true],
                        tran.GetText('Bez souhlasu s Všeobecnými podmínkami nelze pokračovat'),
                    ),
                customerEmail: Yup.string()
                    .matches(EmailRegex, tran.GetText('Email nemá správný formát'))
                    .email(tran.GetText('Nesprávný formát Email-u.'))
                    .required(tran.GetText('Prosím zadejte Váš e-mail.')),
                customerPhone: Yup.string()
                    .matches(PhoneRegExp, tran.GetText('Nesprávna hodnota Telefon'))
                    .max(14, tran.GetText('Maximální počet znaků je 14.'))
                    .min(12, tran.GetText('Minimální počet znaků je 12.'))
                    .when('countryId', {
                        is: (countryId: LanguageType) =>
                            (countryId && countryId === LanguageType.SK) ||
                            countryId === LanguageType.RO ||
                            countryId === LanguageType.IT ||
                            countryId === LanguageType.FR ||
                            countryId === LanguageType.AL,
                        then: Yup.string().required(
                            tran.GetText('Prosím zadejte položku Telefon.'),
                        ),
                    }),
                customerAddress: Yup.string()
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(
                            tran.GetText('Prosím zadejte položku Ulice a číslo.'),
                        ),
                    })
                    // .required(tran.GetText('Prosím zadejte položku Ulice a číslo.'))
                    .max(128, tran.GetText('Maximální počet znaků je 128.')),
                customerCity: Yup.string()
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(tran.GetText('Prosím zadejte položku Město.')),
                    })
                    // .required(tran.GetText('Prosím zadejte položku Město.'))
                    .max(64, tran.GetText('Maximální počet znaků je 64.')),
                customerPsc: Yup.string()
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(tran.GetText('Prosím zadejte položku PSČ.')),
                    })
                    .max(
                        paymentReq?.countryId === LanguageType.RO ? 6 : 5,
                        paymentReq?.countryId === LanguageType.RO
                            ? tran.GetText('Maximální počet znaků je 6.')
                            : tran.GetText('Maximální počet znaků je 5.'),
                    )
                    .matches(/^\d+$/, tran.GetText('PSČ má nesprávný formát.')),
                invoiceCompanyName: Yup.string()
                    .max(128, tran.GetText('Maximální počet znaků je 128.'))
                    .when('invoiceIc', {
                        is: (invoiceIc: string | any) =>
                            invoiceIc &&
                            invoiceIc.length > 0 &&
                            IcValidator(invoiceIc, paymentReq.countryId),
                        then: Yup.string().required(
                            tran.GetText('Prosím zadejte položku Název společnosti.'),
                        ),
                    }),
                invoiceIc: Yup.string()
                    .max(16, tran.GetText('Maximální počet znaků je 16.'))
                    .test('icValTest', tran.GetText('Nesprávná hodnota IČ.'), function (value) {
                        const isValid = IcValidator(value, paymentReq.countryId)
                        return isValid
                    })
                    .when(['invoiceOpened'], {
                        is: (invoiceOpened: boolean) => {
                            return invoiceOpened === true
                        },
                        then: Yup.string().required(tran.GetText('Prosím zadejte IČ společnosti.')),
                    }),
                invoiceDic: Yup.string()
                    .max(18, tran.GetText('Maximální počet znaků je 18.'))
                    .min(
                        countries.find(x => x.id == paymentReq.countryId)?.minDicLength ?? 0,
                        tran.GetText('Nevalidní daňové identifikační číslo'),
                    ),
                requestOptionsModel: Yup.array()
                    .nullable()
                    .test(
                        'requestValTest',
                        tran.GetText('Prosím vyberte jednu z možností.'),
                        function (value) {
                            const isValid = RequestOptionsValidator(
                                paymentReq.requestOptionsModel,
                                paymentReq.countryId,
                            )
                            return isValid
                        },
                    ),
            },
            [
                ['invoiceIc', 'invoiceCompanyName'],
                ['invoiceCompanyName', 'invoiceIc'],
            ],
        )
    }
    const onSubmitAction = async (paymentReq: PaymentReqModel) => {
        appContext.setLoading(true)
        if (paymentReq.paymentMethod === 'CEBIACASH') {
            openPersonaly()
            appContext.setLoading(false)
            return
        }
        if (!paymentReq.invoiceOpened) {
            paymentReq.customerAddress = ''
            paymentReq.customerCity = ''
            paymentReq.customerPsc = ''
            paymentReq.invoiceDic = ''
            paymentReq.invoiceIc = ''
            paymentReq.invoiceCompanyName = ''
            paymentReq.validatedByVies = 0
        }
        const retVal = await setPayment(paymentReq, tran)

        if ((retVal?.length ?? 0) > 0) {
            appContext.setLoading(false)
            //zobrazit error message
            setErrorMessage(retVal)
        }
    }

    const onUpdatePaymentReq = (newPaymentReq: PaymentReqModel) => {
        setPaymentReq(prevState => ({ ...prevState, ...newPaymentReq }))
    }
    return (
        <div className="PagePayment" key={paymentReq?.vin}>
            <Header showLanguage={false} referenceType={ReferenceType.Default} />
            <PageHeader>
                <h1 className="Beta color-darkBlue smwMax:hidden">
                    {tran.GetText('Online platba')}
                </h1>
                <span className="ExaminationCar">
                    {tran.GetText('Prověřované vozidlo')}:{' '}
                    <span className="smwMax:hidden">
                        {paymentReq?.make?.split('|').map((item, index) => (
                            <Fragment key={index}>
                                {item && item.length > 0 ? <>{item + ' '}</> : <></>}
                            </Fragment>
                        ))}
                        {paymentReq?.model?.split('|').map((item, index) => (
                            <Fragment key={index}>
                                {item && item.length > 0 ? <>{item + ' '}</> : <></>}
                            </Fragment>
                        ))}{' '}
                        {tran.GetText('VIN')}:
                    </span>{' '}
                    {paymentReq?.vin ?? ''}
                </span>
                {errorMessage?.length > 0 && (
                    <Container variant="medium" className="mt-20">
                        <StripeAlert variant="error">
                            <span className=" Icon Icon--alert AlertIcon"></span> {errorMessage}
                        </StripeAlert>
                    </Container>
                )}
            </PageHeader>

            {/* <SectionHeader
                updatePaymentState={onUpdatePaymentReq}
                payment={paymentReq}
            ></SectionHeader> */}

            <Container className="box">
                <Container>
                    <SectionShape variant="rightBottomUp" />
                </Container>
                <Formik
                    key={paymentReq.invoiceOpened ? 'opened' : 'closed'}
                    initialValues={paymentReq}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        onSubmitAction(values)
                    }}
                >
                    <Form className="ajax" autoComplete="off">
                        <SectionCustomerCz
                            paymentReq={paymentReq}
                            updatePaymentState={onUpdatePaymentReq}
                        />
                        <SectionPaymentOptions
                            paymentReq={paymentReq}
                            updatePaymentState={onUpdatePaymentReq}
                        ></SectionPaymentOptions>
                        <SectionPayMethod isVisible={paymentReq?.price > 0} />

                        <Section variant="bare" className="SectionPaymentFooter">
                            <div className="Container pl0">
                                <div className="ToggleItemAtr-inner">
                                    <div className="Grid">
                                        <div className="pt-20 w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex">
                                            <div className="text-inline">
                                                <BubbleOpener
                                                    className="contactModal"
                                                    onClickHandler={openc}
                                                />
                                                <a
                                                    className="discountLink"
                                                    onClick={() => openDiscount()}
                                                >
                                                    {tran.GetText('Uplatnit slevový kupon')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <Container className="discount Grid">
                                        <div className="w-12/12 sm:w-6/12 smw:w-6/12 md:w-6/12 flex alignCenter">
                                            {tran.GetText('Prověření historie vozidla')}
                                        </div>
                                        <SectionNoContainerSeparator className="mb-20 mt-20 smw:hidden" />

                                        <div className="w-12/12 sm:w-6/12 smw:w-6/12 md:w-6/12 flex flexColumnEnd">
                                            {paymentReq.isSale ||
                                            paymentReq.discnoutCaption ||
                                            (paymentReq.price != paymentReq.standardPrice &&
                                                !paymentReq.isSale &&
                                                paymentReq.validatedByVies === 0) ||
                                            (paymentReq.standardPrice != paymentReq.price &&
                                                paymentReq.validatedByVies === 0) ? (
                                                <>
                                                    <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex flexEnd">
                                                        <div className="Price Price-cross">
                                                            {getPaymentPrice(
                                                                paymentReq,
                                                                tran,
                                                                false,
                                                                false,
                                                            )}
                                                        </div>
                                                        <div className="Price">
                                                            {getPaymentPrice(
                                                                paymentReq,
                                                                tran,
                                                                false,
                                                                true,
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex flexEnd">
                                                        {paymentReq.discnoutCaption ? (
                                                            <>
                                                                <span className="color-redSale bold">
                                                                    {paymentReq.discnoutCaption}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="capitalize">
                                                                {/* {tran.GetText('SLEVA')} */}
                                                            </span>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-12/12 flex flexEnd">
                                                    <div className="Price">
                                                        {getPaymentPrice(
                                                            paymentReq,
                                                            tran,
                                                            false,
                                                            true,
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Container>
                                    {paymentReq?.price >= 0 &&
                                    paymentReq.vatDeduction &&
                                    paymentReq.validatedByVies === 1 &&
                                    paymentReq.invoiceOpened &&
                                    paymentReq.invoiceDic.length > 0 &&
                                    paymentReq.invoiceCompanyName.length > 0 ? (
                                        <></>
                                    ) : (
                                        <div className="Grid m30">
                                            {paymentReq?.price >= 0 &&
                                                paymentReq?.price != paymentReq.priceWithoutVat && (
                                                    <>
                                                        <div className="pl-50 w-4/12 sm:w-4/12 smw:w-4/12 md:w-4/12 priceInfo flex">
                                                            {tran.GetText('Celkem bez DPH:')}
                                                        </div>
                                                        <div className="pr-50 w-8/12 sm:w-8/12 smw:w-8/12 md:w-8/12 flex priceSmaller">
                                                            {getPaymentPriceWithoutVat(paymentReq)}
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                    )}

                                    <div className="Grid m30">
                                        {paymentReq?.price >= 0 && (
                                            <>
                                                <div className="pl-50 w-4/12 sm:w-4/12 smw:w-4/12 md:w-4/12 priceInfo flex">
                                                    {paymentReq.vatDeduction &&
                                                    paymentReq.validatedByVies === 1 &&
                                                    paymentReq.invoiceOpened &&
                                                    paymentReq.invoiceDic.length > 0 &&
                                                    paymentReq.invoiceCompanyName.length > 0
                                                        ? tran.GetText('Celkem bez DPH')
                                                        : tran.GetText('Celkem s DPH:')}
                                                </div>
                                                <div className="pr-50 w-8/12 sm:w-8/12 smw:w-8/12 md:w-8/12 flex price">
                                                    {getPaymentPrice(paymentReq, tran, false, true)}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <SectionSeparator />
                                    <div className="Grid">
                                        <div className="w-12/12 sm:w-12/12 smw:w-12/12 md:w-8/12 flex agreement centerItems flexColumnEnd">
                                            <Checkbox
                                                className=""
                                                variant="green"
                                                aria-label="Souhlas se zpracováním osobních údajů"
                                                name="isGdpr"
                                                id="isGdpr"
                                            >
                                                <label htmlFor="isGdpr">
                                                    {tran.GetHtml(
                                                        'Dokončením objednávky souhlasím s <a href="https://www.cebia.cz/files/vseob_podm_atr.pdf" target="_blank" class="Link Link--lightBlue">obchodními podmínkami</a> a beru na vědomí <a href="https://www.cebia.cz/o-spolecnosti/ochrana-osobnich-udaju.html" target="_blank" class="Link Link--lightBlue">zásady zpracování osobních údajů</a>.',
                                                    )}
                                                </label>
                                                <ErrorMessage
                                                    name="isGdpr"
                                                    component="div"
                                                    className="InputError"
                                                />
                                            </Checkbox>
                                            {/* {paymentReq.countryId === LanguageType.DE && (
                                                <>
                                                    <Checkbox
                                                        className="pt-20"
                                                        variant="green"
                                                        aria-label="Agreement with data processing"
                                                        name="ownerShipAgreement"
                                                        id="ownerShipAgreement"
                                                    >
                                                        <label htmlFor="isGdpr">
                                                            {tran.GetHtml(
                                                                'Potvrzuji, že jsem vlastníkem vozidla zadaného VIN nebo mám oprávněný zájem k vozidlu a souhlas majitele s nakládáním informací o vozidle.',
                                                            )}
                                                        </label>
                                                        <ErrorMessage
                                                            name="ownerShipAgreement"
                                                            component="div"
                                                            className="InputError"
                                                        />
                                                    </Checkbox>
                                                </>
                                            )} */}
                                        </div>
                                        <div className="pr-30 w-12/12 sm:w-12/12 smw:w-12/12 md:w-4/12 flex centerEnd">
                                            <div className="submitBlock">
                                                <Button
                                                    className="Button--block"
                                                    as="button"
                                                    type="submit"
                                                >
                                                    {tran.GetText('Koupit')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal id="paymentSaleCoupon">
                                <Container className="PaymentCouponSection">
                                    <p className="CouponCaption">
                                        {tran.GetText('Slevu můžete uplatnit pokud:')}
                                        <br />
                                    </p>
                                    <div className="SectionPageHeader-highlights">
                                        <ul>
                                            {paymentReq.countryId === LanguageType.CZ && (
                                                <>
                                                    <li>
                                                        {tran.GetHtml(
                                                            'Jste si již u nás nějaký vůz prověřoval/a, a zadáním čísla kuponu (uveden v obdrženém emailu) do 30 dnů od nákupu získáváte 50% slevu na další prověření.',
                                                        )}
                                                    </li>
                                                    <li>
                                                        {tran.GetHtml(
                                                            'Máte u nás předplacenou službu FLEX a použijete kód uvedený na daňovém dokladu.',
                                                        )}
                                                    </li>
                                                    <li>
                                                        {tran.GetHtml(
                                                            'Vlastníte slevový poukaz, který Vás opravňuje k nákupu kuponu zdarma.',
                                                        )}
                                                    </li>
                                                    <li>
                                                        {tran.GetHtml(
                                                            'Máte poukaz od některého z našich partnerů.',
                                                        )}
                                                    </li>
                                                </>
                                            )}
                                            <li>
                                                {tran.GetHtml(
                                                    'Vlastníte slevový poukaz, který Vás opravňuje k nákupu kuponu zdarma. Uplatníte zadáním čísla kuponu do pole „Zadejte číslo kuponu“.',
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </Container>
                            </Modal>
                            <Modal id="paymentDiscount">
                                <Container className="PaymentCouponSaleSection">
                                    <Title as="header">
                                        <h2 className="color-darkBlue">
                                            {' '}
                                            {tran.GetText('Zadejte číslo kuponu')}
                                        </h2>
                                    </Title>
                                </Container>
                                <SectionSeparator className="pb-15" />
                                <Container className="PaymentCouponSaleSection">
                                    <p className="pb-30">
                                        {tran.GetText(
                                            'V rámci jedné objednávky je možné uplatnit vždy pouze jeden slevový kupon. Slevové kupony nelze sčítat ani vzájemně kombinovat. Sleva se vztahuje pouze na službu prověření historie vozidla.',
                                        )}
                                    </p>
                                </Container>
                                <Container className="PaymentCouponSaleSection">
                                    {paymentReq.isSale && paymentReq.saleCoupon.length > 0 ? (
                                        <p className="text-center">
                                            <label
                                                className="Label color-redSale bold"
                                                htmlFor="saleCoupon"
                                            >
                                                {paymentReq.discnoutCaption}
                                            </label>
                                        </p>
                                    ) : (
                                        <>
                                            <div className="FormItem w260">
                                                <div className="w260">
                                                    <input
                                                        id="saleCoupon"
                                                        name="saleCoupon"
                                                        className="Input is-empty saleCouponText"
                                                        type="text"
                                                        placeholder={tran.GetText(
                                                            'Zadejte číslo slevového kuponu',
                                                        )}
                                                        maxLength={16}
                                                        onChange={val => {
                                                            setCouponValue(val.target.value)
                                                        }}
                                                        value={couponValue}
                                                        disabled={
                                                            paymentReq.isSale &&
                                                            paymentReq.saleCoupon.length > 0
                                                        }
                                                        onKeyUp={e => {
                                                            if (
                                                                e.key === 'Enter' &&
                                                                couponValue.length > 0
                                                            ) {
                                                                if (couponValue.length > 0) {
                                                                    onDiscount(paymentReq.isSale)
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <div className="InputError">{message}</div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="text-center">
                                        <Button
                                            as="button"
                                            variantColor={
                                                paymentReq.isSale &&
                                                paymentReq.saleCoupon.length > 0
                                                    ? 'blue'
                                                    : 'gray'
                                            }
                                            type="button"
                                            variantSize="small"
                                            onClick={() => {
                                                if (
                                                    couponValue.length > 0 ||
                                                    paymentReq.saleCoupon.length > 0
                                                ) {
                                                    onDiscount(paymentReq.isSale)
                                                }
                                            }}
                                        >
                                            {paymentReq.isSale && paymentReq.saleCoupon.length > 0
                                                ? tran.GetText('ZRUŠIT ZADANOU SLEVU')
                                                : tran.GetText('PŘEPOČÍTAT')}
                                        </Button>
                                    </div>
                                </Container>
                            </Modal>
                        </Section>
                        <ScrollToError />
                    </Form>
                </Formik>
                <Modal id="paymentPersonaly">
                    <Container>
                        <Title as="header" variantAlign="center">
                            <h1 className="color-darkBlue"> {tran.GetText('Platba hotově')}</h1>
                        </Title>
                        {tran.GetHtml(
                            'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla. Informace o vozidle získáte okamžitě po úhradě kuponu AUTOTRACER.',
                        )}
                    </Container>
                </Modal>
            </Container>
            <Footer />
        </div>
    )
}
