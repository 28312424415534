import './main.styl'
import TagManager from 'react-gtm-module'
import { Route, Routes } from 'react-router'
import { AppProvider } from '../../contexts/AppContext'
import { NavigationProvider } from '../../hooks'
import { PageAbout } from '../../pages/about'
import { PageArticle } from '../../pages/article'
import { PageArticleDetail } from '../../pages/articleDetail'
import { PageCookies } from '../../pages/cookies'
import { PageDetailDashboard } from '../../pages/detailDashboard'
import { PageDetailPaid } from '../../pages/detailPaid'
import { PageFaq } from '../../pages/faq'
import { PageHomepage } from '../../pages/homepage'
import { PagePayment } from '../../pages/payment'
import ScrollService from '../../services/Scroll'
import { Translate } from '../../translations/CebiaTranslator'
import { TitleMetaSection } from '../TitleMeta'
import { PagePayedRegRouting } from '../PagePayedRegRouting'
import { PageDetailSmartCode } from '../../pages/smartCode'
import { TimeLimitedOffer } from '../TimeLimitedOffer'
import { RobotsProtectRouting } from './RobotsProtectRouting'
import { AffilAdd } from './AffilAdd'
import { PageAboutPayment } from '../../pages/about/payment'
import { PageCebiaNetRegistration } from '../../pages/cebianetRegistration'
import { PageImpressum } from '../../pages/impressum'
import DataLayerWrapper, { DataLayerType } from '../../utils/dataLayerWrapper'
import { SignalRUrlType } from '../../enums/SignalRUrlEnum'

export const MainAppRouting = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-PHZ8WL',
    }

    const tran = new Translate()
    const isExample = window.location.href.toLowerCase().indexOf('/payed/detail/example', 0) >= 0

    TagManager.initialize(tagManagerArgs)

    // const pm = new PayedStateModel()

    ScrollService.useNative()

    return (
        <AppProvider>
            <NavigationProvider>
                <Routes>
                    <Route element={<RobotsProtectRouting />}>
                        <Route element={<AffilAdd />}>
                            <Route
                                path="*"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('Homepage metatitle')}
                                        meta={tran.GetText('Homepage metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageHomepage />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/impressum"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('Homepage metatitle')}
                                        meta={tran.GetText('Homepage metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer> <PageImpressum />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/carinfo/"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('Homepage metatitle')}
                                        meta={tran.GetText('Homepage metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer> <PageHomepage />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/carinfo/payment/"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('Homepage metatitle')}
                                        meta={tran.GetText('Homepage metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer> <PageHomepage />
                                        </>
                                    </TitleMetaSection>
                                }
                            />

                            <Route
                                path="/carinfo/:id"
                                element={
                                    <DataLayerWrapper
                                        type={DataLayerType.Summary}
                                        signalRUrlType={SignalRUrlType.DashBoard}
                                    >
                                        <TitleMetaSection
                                            title={tran.GetText('Homepage metatitle')}
                                            meta={tran.GetText('Homepage metadescription')}
                                        >
                                            <PageDetailDashboard />
                                        </TitleMetaSection>
                                    </DataLayerWrapper>
                                }
                            />
                            <Route
                                path="/carinfo/payment/:id"
                                element={
                                    <DataLayerWrapper
                                        type={DataLayerType.Payment}
                                        signalRUrlType={SignalRUrlType.DashBoard}
                                    >
                                        <TitleMetaSection
                                            title={tran.GetText('Payment metatitle')}
                                            meta={tran.GetText('Payment metadescription')}
                                        >
                                            <PagePayment />
                                        </TitleMetaSection>
                                    </DataLayerWrapper>
                                }
                            />
                            <Route
                                path="/payed/detail/:id"
                                element={
                                    <DataLayerWrapper
                                        type={DataLayerType.Vypis}
                                        signalRUrlType={SignalRUrlType.Payed}
                                    >
                                        <TitleMetaSection
                                            title={
                                                isExample
                                                    ? tran.GetText('Sample metatitle {-}')
                                                    : tran.GetText('Car History Report')
                                            }
                                            meta={
                                                isExample
                                                    ? tran.GetText('Sample metadescription')
                                                    : tran.GetText(
                                                          'Car History Report metadescription',
                                                      )
                                            }
                                        >
                                            <PageDetailPaid />
                                        </TitleMetaSection>
                                    </DataLayerWrapper>
                                }
                            />
                            <Route path="/PayedReg/Index" element={<PagePayedRegRouting />} />
                            <Route path="/PayedReg" element={<PagePayedRegRouting />} />
                            <Route path="/PayedReg/Redirect" element={<PagePayedRegRouting />} />
                            <Route
                                path="/blog/"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('Blog metatitle')}
                                        meta={tran.GetText('Blog metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageArticle />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/faqs/"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('FAQ metatitle')}
                                        meta={tran.GetText('FAQ metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageFaq />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/about/payment"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('O platbě')}
                                        meta={tran.GetText('O platbě')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageAboutPayment />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/about/"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('About metatitle')}
                                        meta={tran.GetText('About metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageAbout />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/about/cookies"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('Cookies metatitle')}
                                        meta={tran.GetText('Cookies metadescription')}
                                    >
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageCookies />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/detailArticle/:title"
                                element={
                                    <TitleMetaSection>
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageArticleDetail />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/cr/:crId/:vinHash"
                                element={
                                    <TitleMetaSection>
                                        <>
                                            <TimeLimitedOffer></TimeLimitedOffer>
                                            <PageHomepage />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            <Route
                                path="/smartCode/:id"
                                element={
                                    <DataLayerWrapper
                                        type={DataLayerType.ZV}
                                        signalRUrlType={SignalRUrlType.Payed}
                                    >
                                        <TitleMetaSection
                                            title={tran.GetText('Historie najetých kilometrů')}
                                        >
                                            <>
                                                <TimeLimitedOffer></TimeLimitedOffer>
                                                <PageDetailSmartCode />
                                            </>
                                        </TitleMetaSection>
                                    </DataLayerWrapper>
                                }
                            />
                            <Route
                                path="/cebianetRegistration"
                                element={
                                    <TitleMetaSection
                                        title={tran.GetText('CebiaNet registrační formulář')}
                                        meta={tran.GetText(
                                            'Tento registrační formulář je určen pouze pro B2B partnery společnosti Cebia.',
                                        )}
                                    >
                                        <>
                                            <PageCebiaNetRegistration />
                                        </>
                                    </TitleMetaSection>
                                }
                            />
                            {/* <Route
                        path="/smartCode/info/:id"
                        element={
                            <TitleMetaSection>
                                <PageInfoSmartCode />
                            </TitleMetaSection>
                        }
                    /> */}
                        </Route>
                    </Route>
                </Routes>
            </NavigationProvider>
        </AppProvider>
    )
}
