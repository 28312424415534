import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { FormItem } from '../../../../components/FormItem'
import { Input } from '../../../../components/Input'
import { ToggleItemAtr } from '../../../../components/ToggleItemAtr'
import { ToggleOpenerAtr } from '../../../../components/ToggleOpenerAtr'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Section } from '../../../../components/Section'
import { Container } from '../../../../components/Container'
import { Modal, useModal } from '../../../../components/Modal'
import { getCompanyInfo, getViesInfo } from '../../../../busLog/paymentBL'
import { useFormikContext } from 'formik'
import { getCountries } from '../../../../busLog/enums_gen'
import { PaymentReqModel } from '../../../../models/paymentReqModel'
import { BubbleOpener } from '../../../../components/BubbleOpener'
import { LanguageType } from '../../../../enums/LanguageType'
import { ReactSelectWithIcon } from '../../../../components/ReactSelect'
import { useAppContext } from '../../../../contexts/AppContext'
import postalCodes from '../../../../utils/postalCodeData.json'
import { Info } from '../../../../components/Info'
import { SectionSeparator } from '../../../homepage/sections/SectionSeparator'
import { SectionNoContainerSeparator } from '../../../homepage/sections/SectionNoContainerSeparator'

type SectionProps = {
    paymentReq: PaymentReqModel
    updatePaymentState: (newPaymentState: PaymentReqModel) => void
}

export const SectionCustomerCz = ({
    paymentReq,
    updatePaymentState,
}: SectionProps): ReactElement => {
    const tran = new Translate()
    const countries = getCountries(tran)
    const { open: opene } = useModal('payEmailModal')
    const { open: opent } = useModal('payPhoneModal')
    const { open: openViesNotFound } = useModal('viesNotFoundModal')
    const { open: openViesNotRespond } = useModal('viesNotRespondModal')
    const { open: openDic } = useModal('payDicModal')
    const { open } = useModal('CustomerModal')
    const formikContext = useFormikContext<PaymentReqModel>()
    const [customerCountry, setCustomerCountry] = useState(countries.find(x => x.code2 === 'CZ'))
    const [invoiceVisible, setInvoiceVisible] = useState(paymentReq.invoiceOpened)
    const [customerPhone, setCustomerPhone] = useState(formikContext.values.customerPhone)
    const [invoiceDic, setInvoiceDic] = useState(formikContext.values.invoiceDic)
    const appContext = useAppContext()
    const clearReadonly = () => {
        document.getElementById('invoiceCompanyName')?.removeAttribute('readonly')
    }

    const setReadOnly = () => {
        if (
            formikContext.values.countryId !== LanguageType.CZ &&
            formikContext.values.countryId !== LanguageType.AL &&
            formikContext.values.countryId !== LanguageType.DE
        ) {
            document.getElementById('invoiceCompanyName')?.setAttribute('readonly', 'true')
        }
    }
    const customerPscLength = formikContext.values.countryId !== LanguageType.RO ? 5 : 6
    const setPrefixInvoiceDic = () => {
        if (!formikContext.values.invoiceDic) {
            setReadOnly()
            if (
                formikContext.values.countryId !== LanguageType.US &&
                formikContext.values.countryId !== LanguageType.AL
            ) {
                formikContext.setFieldValue(
                    'invoiceDic',
                    countries.find(x => x.id === formikContext.values.countryId)?.code2,
                )
                const newPaymentReq = { ...paymentReq }
                newPaymentReq.invoiceDic =
                    countries.find(x => x.id === formikContext.values.countryId)?.code2 ?? ''
                updatePaymentState(newPaymentReq)
                setInvoiceDic(
                    countries.find(x => x.id === formikContext.values.countryId)?.code2 ?? '',
                )
            }
        }
    }
    const getDicFromApi = async (dic: string) => {
        const retVal = await getCompanyInfo(dic)
        if (retVal) {
            formikContext.setFieldValue('invoiceCompanyName', retVal.companyName)
        }
    }

    const getViesInfoFromApi = async (dic: string) => {
        if (dic.length > 2 && formikContext.values.countryId !== LanguageType.AL) {
            appContext.setLoading(true)
            const retVal = await getViesInfo(dic)

            if (retVal && retVal.valid) {
                setReadOnly()
                appContext.setLoading(false)
                let regex = postalCodes.postalCodeData['CZ']
                Object.entries(postalCodes.postalCodeData).map(postCode => {
                    if (postCode[0] == retVal.countryCode) {
                        regex = postCode[1]
                    }
                })
                let indexOfPsc = 0
                retVal.address.split('\n').forEach((item, index) => {
                    if (item.match(regex)?.toString()) indexOfPsc = index
                })
                const psc = retVal.address.split('\n')[indexOfPsc].match(regex)?.toString()
                let mesto = retVal.address
                    .split('\n')
                    [indexOfPsc].replace(psc ?? '', '')
                    .trim()

                let ulice = retVal.address.split('\n')[retVal.countryCode == 'RO' ? 1 : 0]
                if (retVal.countryCode.toLowerCase() == 'ro') {
                    const indexOfStreet = retVal.address.toLowerCase().indexOf('str.')
                    const indexOfComma = retVal.address.toLowerCase().indexOf(',')
                    if (indexOfStreet > -1) {
                        mesto = retVal.address
                            .substring(0, indexOfStreet)
                            .replace(psc ?? '', '')
                            .trim()
                        ulice = retVal.address.substring(indexOfStreet).trim()
                    }
                    if (indexOfComma > -1 && indexOfStreet == -1) {
                        mesto = retVal.address
                            .substring(0, indexOfComma)
                            .replace(psc ?? '', '')
                            .trim()
                        ulice = retVal.address.substring(indexOfComma + 1).trim()
                    }
                }
                if (retVal.name.startsWith('---')) {
                    retVal.name = ''
                }
                if (ulice.startsWith('---')) {
                    ulice = ''
                }
                if (mesto.startsWith('---')) {
                    mesto = ''
                }
                if (mesto.startsWith('---')) {
                    mesto = ''
                }
                formikContext.setFieldValue('invoiceCompanyName', retVal.name)
                formikContext.setFieldValue('customerAddress', ulice)
                formikContext.setFieldValue('customerCity', mesto)
                formikContext.setFieldValue('customerPsc', psc?.replace(' ', ''))
                formikContext.setFieldValue('validatedByVies', 1)
                const newPaymentReq = { ...paymentReq }
                newPaymentReq.invoiceCompanyName = retVal.name
                newPaymentReq.customerAddress = ulice
                newPaymentReq.customerCity = mesto
                newPaymentReq.invoiceDic = dic
                newPaymentReq.customerPsc = psc?.replace(' ', '') ?? ''
                newPaymentReq.validatedByVies = 1
                updatePaymentState(newPaymentReq)
            }
            if (!retVal?.valid) {
                clearReadonly()
                appContext.setLoading(false)
                if (retVal?.error) {
                    openViesNotRespond()
                } else {
                    openViesNotFound()
                }
                const newPaymentReq = { ...paymentReq }

                newPaymentReq.validatedByVies = 0
                setPrefixInvoiceDic()
                updatePaymentState(newPaymentReq)
            }
        } else {
            const newPaymentReq = { ...paymentReq }

            newPaymentReq.validatedByVies = 0
            setPrefixInvoiceDic()
            updatePaymentState(newPaymentReq)
        }
    }

    useEffect(() => {
        if (invoiceVisible) {
            setPrefixInvoiceDic()
        }
    }, [invoiceVisible, setInvoiceVisible])

    useEffect(() => {
        const selected = countries.find(x => x.id === formikContext.values.countryId)
        const kosCountry = countries.find(x => x.id === 115)
        if (
            formikContext.values.customerPhone.length == 0 ||
            formikContext.values.customerPhone == selected?.countryPhonePrefix
        ) {
            setCustomerPhone(selected?.countryPhonePrefix ?? '')
        }
        if (formikContext.values.countryId === 3) {
            setCustomerCountry(kosCountry)
            setCustomerPhone(kosCountry?.countryPhonePrefix ?? '')
        } else {
            setCustomerCountry(selected)
        }
    }, [formikContext.values.customerCountry])
    return (
        <Section variant="bare" className="SectionCustomerInfo">
            <Container className="pt-50">
                <ToggleOpenerAtr
                    className="TogglePadding sectionEmail"
                    title={tran.GetText('Vaše údaje')}
                    href="#sectionEmail"
                    atrInfoStatus={{}}
                    status=""
                    isOpened={true}
                    isStatic={true}
                />
                <ToggleItemAtr active={true} id="sectionEmail" className="sectionInvoiceItem">
                    <div className="Grid gap-30 mb-20">
                        <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                            <Input
                                id="customerEmail"
                                name="customerEmail"
                                autocompleteoff={true}
                                type="text"
                                placeholder={tran.GetText('Vaše e-mailová adresa')}
                                label={tran.GetText('Kontaktní e-mail')}
                                labelQuestionHandler={opene}
                                maxLength={64}
                                value={formikContext.values.customerEmail}
                                onChange={val => {
                                    formikContext.setFieldValue('customerEmail', val ?? '')
                                    const newPaymentReq = { ...paymentReq }
                                    newPaymentReq.customerEmail = val ?? ''
                                    updatePaymentState(newPaymentReq)
                                }}
                            />
                        </FormItem>
                        {formikContext.values.countryId === LanguageType.CZ && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'CZ')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? LanguageType.CZ,
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = val?.id ?? LanguageType.CZ
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}

                        {formikContext.values.countryId === LanguageType.RO && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'RO')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? LanguageType.RO,
                                        )
                                        const newPaymentReq = { ...paymentReq }
                                        newPaymentReq.customerCountry = val?.id ?? LanguageType.RO
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}

                        {formikContext.values.countryId === LanguageType.IT && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'IT')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? LanguageType.IT,
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = val?.id ?? LanguageType.IT
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}

                        {formikContext.values.countryId === LanguageType.DE && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'DE')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? LanguageType.DE,
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = val?.id ?? LanguageType.DE
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}

                        {formikContext.values.countryId === LanguageType.FR && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'FR')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? LanguageType.FR,
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = val?.id ?? LanguageType.FR
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}

                        {formikContext.values.countryId === LanguageType.NL && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'NL')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? LanguageType.NL,
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = val?.id ?? LanguageType.NL
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}

                        {formikContext.values.countryId === LanguageType.SK && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'SK')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? LanguageType.SK,
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = val?.id ?? LanguageType.SK
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}
                        {formikContext.values.countryId === LanguageType.UK && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(x => x.code2 === 'UA')}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            val?.id ?? 'UA',
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = val?.id ?? 'UA'
                                        updatePaymentState(newPaymentReq)
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}
                        {formikContext.values.countryId === LanguageType.AL && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <ReactSelectWithIcon
                                    isMulti={false}
                                    textLabelOption="name"
                                    textValueOption="id"
                                    isSearchable={false}
                                    isDisabled={false}
                                    optionImageHeight={30}
                                    id="customerCountry"
                                    name="customerCountry"
                                    label={tran.GetText('Stát')}
                                    value={customerCountry}
                                    options={countries.filter(
                                        x =>
                                            x.code2 === 'AL' ||
                                            x.code2 === 'XK' ||
                                            x.code2 === 'MK',
                                    )}
                                    onChange={val => {
                                        setCustomerCountry(val)
                                        formikContext.setFieldValue(
                                            'customerCountry',
                                            LanguageType.AL,
                                        )
                                        const newPaymentReq = { ...paymentReq }

                                        newPaymentReq.customerCountry = LanguageType.AL
                                        updatePaymentState(newPaymentReq)
                                        setCustomerPhone(val?.countryPhonePrefix ?? '')
                                    }}
                                ></ReactSelectWithIcon>
                            </FormItem>
                        )}
                        {(formikContext.values.countryId === LanguageType.SK ||
                            formikContext.values.countryId === LanguageType.RO ||
                            formikContext.values.countryId === LanguageType.IT ||
                            formikContext.values.countryId === LanguageType.FR ||
                            formikContext.values.countryId === LanguageType.NL ||
                            formikContext.values.countryId === LanguageType.DE ||
                            formikContext.values.countryId === LanguageType.AL) && (
                            <FormItem className="FormItem smw:w-1/2 md:w-1/2 lg:w-4/12 emailTextItem">
                                <Input
                                    id="customerPhone"
                                    name="customerPhone"
                                    autocompleteoff={true}
                                    type="tel"
                                    placeholder={tran.GetText('Telefon')}
                                    label={tran.GetText('Telefon')}
                                    maxLength={13}
                                    value={customerPhone}
                                    onChange={val => {
                                        const newPaymentReq = { ...paymentReq }
                                        if (
                                            (val &&
                                                !val.startsWith(
                                                    customerCountry?.countryPhonePrefix ?? '',
                                                    0,
                                                )) ||
                                            val?.length == 0
                                        ) {
                                            val = customerCountry?.countryPhonePrefix ?? ''
                                            formikContext.setFieldValue('customerPhone', val)
                                            setCustomerPhone(val)

                                            newPaymentReq.customerPhone = val
                                        }
                                        if (
                                            val &&
                                            val.startsWith(
                                                customerCountry?.countryPhonePrefix ?? '',
                                                0,
                                            )
                                        ) {
                                            setCustomerPhone(val)
                                            formikContext.setFieldValue('customerPhone', val ?? '')
                                            newPaymentReq.customerPhone = val
                                        }
                                        updatePaymentState(newPaymentReq)
                                    }}
                                />
                            </FormItem>
                        )}
                    </div>
                    <SectionNoContainerSeparator className="mb-30" />

                    {formikContext.values.countryId !== LanguageType.UK && (
                        <div className="Grid gap-30 mb-30">
                            <div className="smw:w-1/2 md:w-1/2 lg:w-6/12">
                                <label className="Checkbox Checkbox--green">
                                    <input
                                        name="isInvoiceData"
                                        type="checkbox"
                                        id="isInvoiceData"
                                        className="Checkbox-input"
                                        value=""
                                        checked={
                                            paymentReq.invoiceOpened ||
                                            paymentReq.validatedByVies === 1
                                        }
                                        onChange={() => {
                                            setInvoiceVisible(prevState => {
                                                if (prevState) {
                                                    formikContext.setFieldValue(
                                                        'invoiceCompanyName',
                                                        '',
                                                    )
                                                    formikContext.setFieldValue(
                                                        'customerAddress',
                                                        '',
                                                    )
                                                    formikContext.setFieldValue('customerCity', '')
                                                    formikContext.setFieldValue('customerPsc', '')
                                                    formikContext.setFieldValue(
                                                        'validatedByVies',
                                                        0,
                                                    )
                                                    const newPaymentReq = { ...paymentReq }

                                                    newPaymentReq.invoiceCompanyName = ''
                                                    newPaymentReq.customerAddress = ''
                                                    newPaymentReq.customerCity = ''
                                                    newPaymentReq.invoiceDic = ''
                                                    newPaymentReq.invoiceIc = ''
                                                    newPaymentReq.customerPsc = ''
                                                    newPaymentReq.validatedByVies = 0
                                                    newPaymentReq.invoiceOpened = false
                                                    updatePaymentState(newPaymentReq)
                                                } else {
                                                    const newPaymentReq = { ...paymentReq }

                                                    newPaymentReq.invoiceOpened = true
                                                    updatePaymentState(newPaymentReq)
                                                }
                                                return !prevState
                                            })
                                        }}
                                    />
                                    <span className="Checkbox-placeholder"></span>
                                    <span className="Checkbox-label">
                                        <label htmlFor="isInvoiceData">
                                            {tran.GetText('Nakupuji na firmu')}
                                            <BubbleOpener
                                                className="invoiceModal"
                                                onClickHandler={open}
                                            />
                                        </label>
                                    </span>
                                </label>
                            </div>
                            <div className="smw:w-1/2 md:w-1/2 lg:w-4/12"></div>
                        </div>
                    )}

                    {/* {formikContext.values.countryId === LanguageType.RO && (
                        <div className="Grid gap-30 mb-10">
                            <FormItem className="FormItem smw:w-1/2 md:w-12/12">
                                {tran.GetText(
                                    'Chcete nakoupit na firmu nebo prověřit více vozidel? Využijte naše',
                                )}{' '}
                                <a href="#" onClick={openPackage}>
                                    {tran.GetText('zvýhodněné balíčky!')}
                                </a>
                            </FormItem>
                        </div>
                    )} */}
                    <Modal id="payDicModal">
                        <Container>
                            {tran.GetHtml(
                                'Pokud vyplníte DIČ, pokusíme se zbylé údaje doplnit z oficiální databáze.',
                            )}
                        </Container>
                    </Modal>
                    {/* <Modal id="payPackageModal">
                        <Container>
                            <PackageForm></PackageForm>
                        </Container>
                    </Modal> */}
                    <Modal id="payEmailModal">
                        <Container>
                            {tran.GetHtml(
                                'Na uvedenou e-mailovou adresu Vám po platbě zašleme odkaz na zobrazení informací o historii Vašeho vozu a zjednodušený daňový doklad.',
                            )}
                        </Container>
                    </Modal>
                    <Modal id="payPhoneModal">
                        <Container>
                            {tran.GetHtml(
                                'Na uvedený telefón Vám po provedení úhrady budou zaslány informace potřebné pro zobrazení zaplacených informací a zjednodušený daňový doklad k platbě.',
                            )}
                        </Container>
                    </Modal>
                    <Modal id="viesNotFoundModal">
                        <Container>
                            <div className="Grid">
                                <div className="Grid-cell md:w-12/12">
                                    <div className="flex modalVies mb-20">
                                        <Info variant="alert" withIcon={true}>
                                            <h2 className="color-red">
                                                {' '}
                                                {tran.GetText('Nepotvrzené DIČ')}
                                            </h2>
                                        </Info>
                                    </div>
                                    <SectionSeparator className="mb-20" />
                                    <p>
                                        {tran.GetHtml(
                                            'Oficiální databáze plátců DPH nepotvrdila Vaše DIČ. Nemůžeme proto automaticky doplnit Vaše údaje. Zkontrolujte prosím zadané DIČ nebo vyplňte údaje o Vaší firmě ručně.',
                                        )}
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </Modal>
                    <Modal id="viesNotRespondModal">
                        <Container>
                            <div className="Grid">
                                <div className="Grid-cell md:w-12/12">
                                    <div className="flex modalVies mb-20">
                                        <Info variant="alert" withIcon={true}>
                                            <h2 className="color-red">
                                                {' '}
                                                {tran.GetText('Databáze neodpovídá')}
                                            </h2>
                                        </Info>
                                    </div>
                                    <SectionSeparator className="mb-20" />
                                    <p>
                                        {tran.GetHtml(
                                            'Oficiální databáze plátců DPH nereaguje. Vyplňte prosím údaje o Vaší firmě ručně.',
                                        )}
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </Modal>
                    {invoiceVisible && (
                        <>
                            <div className="Grid gap-30 gmb-20">
                                <FormItem className="smw:w-4/12 md:w-full lg:w-4/12">
                                    <Input
                                        autocompleteoff={true}
                                        id="invoiceDic"
                                        name="invoiceDic"
                                        type="text"
                                        labelQuestionHandler={openDic}
                                        placeholder={tran.GetText('Daňové identifikační číslo')}
                                        label={tran.GetText('DIČ')}
                                        maxLength={
                                            countries.find(x => x.id == paymentReq.countryId)
                                                ?.dicLength
                                        }
                                        value={invoiceDic}
                                        onBlur={e => getViesInfoFromApi(e.currentTarget.value)}
                                        onChange={val => {
                                            if (!val) {
                                                clearReadonly()
                                            }
                                            const newPaymentReq = { ...paymentReq }

                                            if (
                                                newPaymentReq.countryId === LanguageType.IT &&
                                                !val?.startsWith('IT', 0)
                                            ) {
                                                val = 'IT'
                                            }
                                            if (
                                                newPaymentReq.countryId === LanguageType.FR &&
                                                !val?.startsWith('FR', 0)
                                            ) {
                                                val = 'FR'
                                            }
                                            if (
                                                newPaymentReq.countryId === LanguageType.RO &&
                                                !val?.startsWith('RO', 0)
                                            ) {
                                                val = 'RO'
                                            }
                                            if (
                                                newPaymentReq.countryId === LanguageType.SK &&
                                                !val?.startsWith('SK', 0)
                                            ) {
                                                val = 'SK'
                                            }
                                            if (
                                                newPaymentReq.countryId === LanguageType.CZ &&
                                                !val?.startsWith('CZ', 0)
                                            ) {
                                                val = 'CZ'
                                            }
                                            if (
                                                newPaymentReq.countryId === LanguageType.NL &&
                                                !val?.startsWith('NL', 0)
                                            ) {
                                                val = 'NL'
                                            }
                                            if (val?.length ?? 0 > 1) setReadOnly()
                                            setInvoiceDic(val ?? '')
                                            formikContext.setFieldValue('invoiceDic', val ?? '')
                                            newPaymentReq.invoiceDic = val ?? ''
                                            updatePaymentState(newPaymentReq)
                                        }}
                                    />
                                </FormItem>
                                <FormItem className="smw:w-4/12 md:w-full lg:w-4/12">
                                    <Input
                                        autocompleteoff={true}
                                        id="invoiceCompanyName"
                                        name="invoiceCompanyName"
                                        type="text"
                                        placeholder={tran.GetText('Název společnosti')}
                                        label={tran.GetText('Název společnosti')}
                                        maxLength={128}
                                        onChange={val => {
                                            formikContext.setFieldValue(
                                                'invoiceCompanyName',
                                                val ?? '',
                                            )
                                            const newPaymentReq = { ...paymentReq }

                                            newPaymentReq.invoiceCompanyName = val ?? ''
                                            updatePaymentState(newPaymentReq)
                                        }}
                                    />
                                </FormItem>
                                <FormItem className="smw:w-4/12 md:w-full lg:w-4/12">
                                    <Input
                                        autocompleteoff={true}
                                        id="invoiceIc"
                                        name="invoiceIc"
                                        type="text"
                                        placeholder={tran.GetText('Identifikační číslo firmy')}
                                        label={tran.GetText('IČ')}
                                        maxLength={16}
                                        onChange={val => {
                                            formikContext.setFieldValue('invoiceIc', val ?? '')
                                            const newPaymentReq = { ...paymentReq }

                                            newPaymentReq.invoiceIc = val ?? ''
                                            updatePaymentState(newPaymentReq)
                                        }}
                                    />
                                </FormItem>
                            </div>
                            <div className="Grid gap-30 mb-10">
                                <FormItem className="smw:w-4/12 md:w-full lg:w-4/12">
                                    <Input
                                        autocompleteoff={true}
                                        id="customerAddress"
                                        name="customerAddress"
                                        type="text"
                                        placeholder={tran.GetText('Ulice a číslo')}
                                        label={tran.GetText('Ulice a číslo')}
                                        maxLength={64}
                                        onChange={val => {
                                            formikContext.setFieldValue(
                                                'customerAddress',
                                                val ?? '',
                                            )
                                            const newPaymentReq = { ...paymentReq }

                                            newPaymentReq.customerAddress = val ?? ''
                                            updatePaymentState(newPaymentReq)
                                        }}
                                    />
                                </FormItem>
                                <FormItem className="smw:w-4/12 md:w-full lg:w-4/12">
                                    <Input
                                        autocompleteoff={true}
                                        id="customerCity"
                                        name="customerCity"
                                        type="text"
                                        placeholder={tran.GetText('Město')}
                                        label={tran.GetText('Město')}
                                        maxLength={64}
                                        onChange={val => {
                                            formikContext.setFieldValue('customerCity', val ?? '')
                                            const newPaymentReq = { ...paymentReq }

                                            newPaymentReq.customerCity = val ?? ''
                                            updatePaymentState(newPaymentReq)
                                        }}
                                    />
                                </FormItem>
                                <FormItem className="smw:w-4/12 md:w-full lg:w-4/12">
                                    <Input
                                        autocompleteoff={true}
                                        id="customerPsc"
                                        name="customerPsc"
                                        type="text"
                                        placeholder={tran.GetText('PSČ')}
                                        label={tran.GetText('PSČ')}
                                        maxLength={customerPscLength}
                                        className="PscInput"
                                        onChange={val => {
                                            formikContext.setFieldValue('customerPsc', val ?? '')
                                            const newPaymentReq = { ...paymentReq }

                                            newPaymentReq.customerPsc = val ?? ''
                                            updatePaymentState(newPaymentReq)
                                        }}
                                    />
                                </FormItem>
                            </div>
                        </>
                    )}
                    <Modal id="CustomerModal">
                        <Container>
                            {tran.GetHtml(
                                'Pokud chcete mít na zjednodušeném daňovém dokladu vyplněny Vaše fakturační údaje (firmu, adresu, IČ, DIČ), zadejte je prosím do polí níže. Pokud vyplníte DIČ, pokusíme se zbylé údaje doplnit z oficiální databáze.',
                            )}
                        </Container>
                    </Modal>
                    <SectionNoContainerSeparator />
                </ToggleItemAtr>
            </Container>
        </Section>
    )
}
