import React from 'react'
import { makeObservable, Observable } from '../../utils/makeObservable'

type ModalId = string
type ModalState = {
    active: boolean
    onClose?: () => void // Callback při zavření
}
type ModalActions = {
    open: () => void
    close: () => void
    active: boolean
}
type Modals = Record<ModalId, Observable<ModalState>>

const defaultState = {
    active: false,
}

const modals: Modals = {}

const getModalStore = (id: ModalId, options: Partial<ModalState> = {}): Observable<ModalState> => {
    if (!modals[id]) {
        const modal: ModalState = { ...defaultState, ...options }
        modals[id] = makeObservable<ModalState>(modal)
    }

    return modals[id]
}

export const useModal = (id: string, options?: Partial<ModalState>): ModalActions => {
    const modalStore = getModalStore(id, options)

    const [state, setModalState] = React.useState(modalStore.get())

    React.useEffect(() => {
        return modalStore.subscribe(setModalState)
    }, [])

    const actions = React.useMemo((): ModalActions => {
        return {
            active: state.active,
            open: () => modalStore.set({ ...state, active: true }),
            close: () => {
                // Zavři modální okno a zavolej onClose, pokud existuje
                modalStore.set({ ...state, active: false })
                if (state.onClose) {
                    state.onClose()
                }
            },
        }
    }, [state])

    return actions
}
