import { useState } from 'react'
import TagManager from 'react-gtm-module'
import { PayedStateModel } from '../models/payedResponseModel'
import { Translate } from '../translations/CebiaTranslator'
import { i18n } from '../translations/i18n'

export function SendEcommerce(model: PayedStateModel | undefined) {
    const [stateEcommerce, setSended] = useState(0)

    if (
        model !== null &&
        model !== undefined &&
        model?.ecommerceData !== undefined &&
        model?.ecommerceData !== null &&
        model?.ecommerceData.firstUse === true &&
        stateEcommerce == 0
    ) {
        setSended(prevState => prevState + 1)

        TagManager.dataLayer({
            dataLayer: {
                event: 'EEpurchase' + i18n.language.toUpperCase(),
                ecommerce: {
                    currencyCode: model?.ecommerceData.currencyCode,
                    purchase: {
                        actionField: {
                            id: model?.ecommerceData.cuponNumber,
                            affiliation: model?.ecommerceData.discountCode,
                            revenue: model?.ecommerceData.price?.toFixed(2),
                            shipping: '0.00',
                            coupon: model?.ecommerceData.cuponSale
                                ? model?.ecommerceData.cuponSale
                                : '',
                            customeremail: model?.ecommerceData.email,
                        },
                    },
                    products: [
                        {
                            name: model?.ecommerceData.productName,
                            id: model?.carVin,
                            price: model?.ecommerceData.price.toFixed(2),
                            brand: model?.carMake ?? '',
                            category: model?.carModel ?? '',
                            variant: model?.druh ?? '',
                            quantity: 1,
                            coupon: '',
                        },
                    ],
                },
            },
        })
    }
}
