import { ReactElement, useEffect, useState } from 'react'
import { PaymentReqModel } from '../../../../models/paymentReqModel'
import { Section } from '../../../../components/Section'
import { Container } from '../../../../components/Container'
import { LanguageType } from '../../../../enums/LanguageType'
import { ToggleOpenerAtr } from '../../../../components/ToggleOpenerAtr'
import { Translate } from '../../../../translations/CebiaTranslator'
import { ToggleItemAtr } from '../../../../components/ToggleItemAtr'
import { FormItem } from '../../../../components/FormItem'
import { RadioButton } from '../../../../components/RadioButton'
import { RequestOptionsModel } from '../../../../models/requestOptionsModel'
import { useFormikContext } from 'formik'
import './index.styl'

type SectionProps = {
    paymentReq: PaymentReqModel
    updatePaymentState: (newPaymentState: PaymentReqModel) => void
}

export const SectionPaymentOptions = ({
    paymentReq,
    updatePaymentState,
}: SectionProps): ReactElement => {
    const tran = new Translate()

    const [requestOptionsModel, setRequestOptionsModel] = useState<RequestOptionsModel>()
    const formikContext = useFormikContext<PaymentReqModel>()
    const [error, setError] = useState('')
    if (
        !requestOptionsModel &&
        paymentReq.requestOptionsModel &&
        paymentReq.requestOptionsModel.find(
            x => x.partnerTypId === 152 || x.partnerTypId === 156 || x.partnerTypId === 159,
        )
    ) {
        setRequestOptionsModel(
            paymentReq.requestOptionsModel.find(
                x => x.partnerTypId === 152 || x.partnerTypId === 156 || x.partnerTypId === 159,
            ),
        )
    } else if (!requestOptionsModel) {
        setRequestOptionsModel(new RequestOptionsModel())
    }
    function onClick(e: React.ChangeEvent<HTMLInputElement>) {
        const payment = { ...paymentReq }
        if (requestOptionsModel) {
            setError('')
            requestOptionsModel.isValid = true
            if (paymentReq?.customerCountry === LanguageType.NL) {
                requestOptionsModel.partnerTypId = 159
            } else if (paymentReq?.customerCountry === LanguageType.RO) {
                requestOptionsModel.partnerTypId = 152
            } else {
                requestOptionsModel.partnerTypId = 156
            }
            if (e.target.value == 'true' && requestOptionsModel) {
                requestOptionsModel.valueBit = true
            }
            if (e.target.value == 'false' && requestOptionsModel) {
                requestOptionsModel.valueBit = false
            }
            if (e.target.value == 'null' && requestOptionsModel) {
                requestOptionsModel.valueBit = undefined
            }
            setRequestOptionsModel(prevState => ({ ...prevState, ...requestOptionsModel }))
            if (requestOptionsModel?.id ?? 0 > 0) {
                const index = payment.requestOptionsModel.findIndex(
                    x => x.id == requestOptionsModel.id,
                )
                payment.requestOptionsModel[index] = requestOptionsModel
            } else {
                if (payment.requestOptionsModel.length == 1) {
                    payment.requestOptionsModel[0] = requestOptionsModel
                } else {
                    payment.requestOptionsModel.push(requestOptionsModel)
                }
            }

            updatePaymentState(payment)
        }
    }
    useEffect(() => {
        if (formikContext?.errors?.requestOptionsModel?.length ?? 0 > 0) {
            if (formikContext.touched.requestOptionsModel?.length == 0) {
                setError(formikContext?.errors?.requestOptionsModel?.toString() ?? '')
            }
        } else {
            setError('')
        }
    })
    if (
        paymentReq?.customerCountry === LanguageType.RO ||
        paymentReq?.customerCountry === LanguageType.IT ||
        paymentReq?.customerCountry === LanguageType.NL
    ) {
        return (
            <Section variant="bare" className="SectionPaymentOptions">
                <Container>
                    <ToggleOpenerAtr
                        className="TogglePadding SectionPaymentOptions"
                        title={tran.GetText('Informace o vozidle')}
                        href="#sectionOptions"
                        atrInfoStatus={{}}
                        status=""
                        isOpened={true}
                        isStatic={true}
                    >
                        <div></div>
                    </ToggleOpenerAtr>
                    <ToggleItemAtr active={true} id="sectionOptions" className="sectionInvoiceItem">
                        <div className="pb-5 md:w-12/12">
                            <label className="Label">
                                {tran.GetText('Je nebo bylo vozidlo registrováno v Česku?')}
                            </label>
                        </div>
                        <FormItem className="FormItem w-2/12 smw:w-2/12 md:w-2/12 lg:w-2/12  mb-0">
                            <RadioButton
                                id={'requestOptions'}
                                name={'requestOptions'}
                                checked={
                                    requestOptionsModel?.valueBit == true &&
                                    (requestOptionsModel?.partnerTypId == 152 ||
                                        requestOptionsModel?.partnerTypId == 156 ||
                                        requestOptionsModel?.partnerTypId == 159)
                                }
                                value={'true'}
                                onClick={onClick}
                            >
                                <div className="d-flex text-noWrap">{tran.GetText('Ano')}</div>
                            </RadioButton>
                        </FormItem>
                        <FormItem className="FormItem w-2/12 smw:w-2/12 md:w-2/12 lg:w-2/12  mb-0">
                            <RadioButton
                                id={'requestOptions'}
                                name={'requestOptions'}
                                checked={
                                    requestOptionsModel?.valueBit == false &&
                                    (requestOptionsModel?.partnerTypId == 152 ||
                                        requestOptionsModel?.partnerTypId == 156 ||
                                        requestOptionsModel?.partnerTypId == 159)
                                }
                                value={'false'}
                                onClick={onClick}
                            >
                                <div className="d-flex text-noWrap">{tran.GetText('Ne')}</div>
                            </RadioButton>
                        </FormItem>
                        <FormItem className="FormItem w-2/12 smw:w-2/12 md:w-2/12 lg:w-2/12 mb-0">
                            <RadioButton
                                id={'requestOptions'}
                                name={'requestOptions'}
                                value={'null'}
                                checked={
                                    requestOptionsModel?.valueBit == null &&
                                    (requestOptionsModel?.partnerTypId == 152 ||
                                        requestOptionsModel?.partnerTypId == 156 ||
                                        requestOptionsModel?.partnerTypId == 159)
                                }
                                onClick={onClick}
                            >
                                <div className="d-flex text-noWrap">{tran.GetText('Nevím')}</div>
                            </RadioButton>
                        </FormItem>
                        {(error.length ?? 0) > 0 && <div className="InputError">{error}</div>}
                    </ToggleItemAtr>
                </Container>
            </Section>
        )
    }
    return <></>
}
