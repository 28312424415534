import './index.styl'
import { ReactElement, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Modal, useModal } from '../../../../components/Modal'
import { Container } from '../../../../components/Container'
import { Title } from '../../../../components/Title'
import ContactValuesModel from '../../../../models/ContactValuesModel'
import * as Yup from 'yup'
import { EmailRegex, PhoneRegExp } from '../../../../utils/validators'
import { Form, Formik } from 'formik'
import { getUserModel, sendTachoContact } from '../../../../busLog/contactFormBL'
import { Button } from '../../../../components/Button'
import { FormItem } from '../../../../components/FormItem'
import { Input, InputChangeHandler } from '../../../../components/Input'
import { Toast } from 'primereact/toast'
import {
    FileUpload,
    FileUploadFile,
    FileUploadHeaderTemplateOptions,
    FileUploadSelectEvent,
    FileUploadUploadEvent,
    ItemTemplateOptions,
} from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar'
import { Tag } from 'primereact/tag'
import classNames from 'classnames'
import { useAppContext } from '../../../../contexts/AppContext'
import { use } from 'i18next'

export const SectionTachoContactForm = ({ sourceData }: DetailPayedProps): ReactElement => {
    const tran = new Translate()
    const { open: openTacho } = useModal('tachoModal')
    const [contactVal, setContactVal] = useState(new ContactValuesModel(false))
    const [prevValPhone, setPrevValPhone] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [sendedMessage, setSendedMessage] = useState(false)
    const toast = useRef<Toast>(null)
    const [totalSize, setTotalSize] = useState(0)
    const fileUploadRef = useRef<FileUpload>(null)
    const appContext = useAppContext()
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'gif', 'pdf', 'heic']
    const payedStateModel = appContext.payedStateModel
    const validationSchema = () => {
        return Yup.object({
            firstName: Yup.string()
                .required(tran.GetText('Prosím zadejte položku Jméno'))
                .max(64, tran.GetText('Maximální počet znaků je 64.')),
            phone: Yup.string()
                .matches(PhoneRegExp, tran.GetText('Nesprávna hodnota Telefon'))
                .required(tran.GetText('Prosím zadejte položku Telefon'))
                .max(14, tran.GetText('Maximální počet znaků je 14.')),
            email: Yup.string()
                .matches(EmailRegex, tran.GetText('Email nemá správný formát'))
                .email(tran.GetText('Email nemá správný formát'))
                .required(tran.GetText('Prosím zadejte položku Email'))
                .max(64, tran.GetText('Maximální počet znaků je 64.')),
            message: Yup.string().when('isBusiness', (isBusiness, schema) => {
                if (!isBusiness)
                    return schema.required(tran.GetText('Prosím zadejte položku Zpráva'))
                return schema
            }),
        })
    }

    const handlePhoneChange: InputChangeHandler = (value, event) => {
        const re = /^(\+){0,1}(\d+){0,14}$/
        if (!value || re.test(value ?? '')) {
            setPrevValPhone(prevValPhone => value ?? '')
            event.currentTarget.value = value ?? ''
            const model = contactVal
            model.phone = value?.toString() ?? ''
            setContactVal(model)
        } else event.currentTarget.value = prevValPhone ?? ''
    }

    const onTemplateSelect = (e: FileUploadSelectEvent) => {
        const validFiles: FileUploadFile[] = []
        let _totalSize = 0
        setErrorMessage('')

        e.files.forEach(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase()

            // Kontrola přípony souboru
            if (!allowedExtensions.includes(fileExtension || '')) {
                setErrorMessage(tran.GetText('Nepovolený typ souboru.') + ' ' + file.name)
            } else {
                validFiles.push(file)
                _totalSize += file.size || 0
            }
        })

        // Nastavíme pouze validní soubory
        setTotalSize(_totalSize)
        fileUploadRef.current?.setFiles(validFiles) // Přidá jen validní soubory
    }

    const onTemplateUpload = (e: FileUploadUploadEvent) => {
        let _totalSize = 0

        e.files.forEach(file => {
            _totalSize += file.size || 0
        })

        setTotalSize(_totalSize)
        toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' })
    }

    const onTemplateRemove = (
        file: File,
        callback: (event: SyntheticEvent<Element, Event>) => void,
    ) => {
        setTotalSize(prevTotalSize => prevTotalSize - (file.size || 0))
        const syntheticEvent = new Event('remove') as unknown as SyntheticEvent
        callback(syntheticEvent) // Tento callback zajišťuje odstranění souboru z UI
    }

    const onTemplateClear = () => {
        setTotalSize(0)
    }

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton } = options
        const value = totalSize / 10000
        const formatedValue =
            fileUploadRef && fileUploadRef.current
                ? fileUploadRef.current.formatSize(totalSize)
                : '0 B'

        return (
            <div
                className={className}
                style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}
            >
                {chooseButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 15 MB</span>
                    <ProgressBar
                        value={(totalSize / (15 * 1024 * 1024)) * 100}
                        showValue={false}
                        style={{ width: '10rem', height: '12px' }}
                    ></ProgressBar>
                </div>
            </div>
        )
    }

    const itemTemplate = (inFile: any, props: ItemTemplateOptions) => {
        const file = inFile as File
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '50%' }}>
                    {file.type === 'application/pdf' ? (
                        <i className="bi bi-filetype-pdf"></i>
                    ) : (
                        <img
                            alt={file.name}
                            role="presentation"
                            src={URL.createObjectURL(file)}
                            width={100}
                        />
                    )}

                    <span className="flex flex-column text-left ml-10">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag
                    value={props.formatSize}
                    severity="warning"
                    className="ml-20 p-tag-warning pt-10 pl-10 pb-10 pr-10"
                />
                <Button
                    type="button"
                    variantSize="small"
                    variantColor="gray"
                    className="ml-auto"
                    onClick={() => onTemplateRemove(file, props.onRemove)}
                >
                    {tran.GetText('Odstranit')}
                </Button>
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i
                    className="pi pi-image mt-3 p-5"
                    style={{
                        fontSize: '5em',
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)',
                    }}
                ></i>
                <span
                    style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
                    className="mb-20"
                >
                    {tran.GetText('Zde přetáhněte soubory')}
                </span>
            </div>
        )
    }

    const chooseOptions = {
        icon: 'mr-2 inline-block',
        iconOnly: false,
        label: 'Vyberte soubory',
        className: classNames('Button Button--small Button--gray mt-10 mb-10'),
    }

    useEffect(() => {
        const fetchData = async () => {
            if (
                (payedStateModel?.apc?.length ?? 0) > 0 &&
                (payedStateModel.countryId === 43 || payedStateModel.countryId === 198)
            ) {
                const result = await getUserModel(payedStateModel.viewId)
                setContactVal(prev => {
                    return {
                        ...prev,
                        email: result?.email ?? '',
                        firstName: result?.jmeno.split(' ')[0] ?? '',
                        lastName: result?.jmeno.split(' ')[1] ?? '',
                    }
                })
            }
        }
        fetchData()
    }, [payedStateModel])

    if (
        (sourceData?.tachoControlResult?.odometerValues?.length ?? 0) > 0 &&
        payedStateModel &&
        (payedStateModel?.apc?.length ?? 0) > 0 &&
        (payedStateModel.countryId === 43 || payedStateModel.countryId === 198)
    )
        return (
            <>
                <p className="text-justify">
                    <span className="warning">{tran.GetText('Poznámka')}: </span>
                    {tran.GetText(
                        'Pokud v záznamech o stavu tachometru evidujete jakoukoliv nesrovnalost, prosím',
                    )}{' '}
                    <a href="#" onClick={openTacho}>
                        {tran.GetText('kontaktujte nás')}
                    </a>
                    .
                </p>
                <Modal id="tachoModal">
                    <Container>
                        <Title as="header" variantAlign="center">
                            <h1 className="color-darkBlue">{tran.GetText('Kontaktní formulář')}</h1>
                        </Title>
                        {sendedMessage ? (
                            <>
                                <h2 className="color-green mb-10">{tran.GetText('Děkujeme')}</h2>
                                <p className="color-darkBlue mb-0">
                                    {tran.GetText('Vaše zpráva byla úspěšně odeslána.')}
                                </p>
                                <p className="color-darkBlue">
                                    {tran.GetText('Brzy se vám ozveme.')}
                                </p>
                            </>
                        ) : (
                            <Formik
                                initialValues={contactVal}
                                enableReinitialize={true}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validationSchema={validationSchema}
                                onSubmit={async values => {
                                    setDisabled(true)

                                    // Získání souborů z FileUpload
                                    const uploadedFiles = fileUploadRef.current?.getFiles() || []
                                    const totalFileSize = uploadedFiles.reduce(
                                        (acc, file) => acc + (file.size || 0),
                                        0,
                                    )

                                    // Kontrola celkové velikosti souborů
                                    if (totalFileSize > 15000000) {
                                        // 15 MB limit
                                        setDisabled(false)
                                        setErrorMessage(
                                            tran.GetText('Soubory přesahují povolený limit.'),
                                        )
                                        return // Zamezí pokračování v odesílání
                                    }

                                    const formData = new FormData()

                                    // Přidání hodnot z formuláře do FormData
                                    formData.append('firstName', values.firstName)
                                    formData.append('lastName', values.lastName)
                                    formData.append('email', values.email)
                                    formData.append('phone', values.phone)
                                    formData.append('message', values.message)
                                    formData.append('viewId', payedStateModel.viewId)
                                    formData.append('apc', payedStateModel.apc)
                                    formData.append('coupon', payedStateModel.couponCode)
                                    formData.append('vin', payedStateModel.carVin)

                                    // Přidání souborů do FormData
                                    uploadedFiles.forEach(file => {
                                        formData.append('files', file)
                                    })

                                    try {
                                        const response = await sendTachoContact(formData) // sendTachoContact nyní očekává FormData
                                        if (response) {
                                            setSendedMessage(true)
                                        } else {
                                            setDisabled(false)
                                            setErrorMessage(
                                                tran.GetText('Chyba uložení. Opakujte akci.'),
                                            )
                                        }
                                    } catch (error) {
                                        setDisabled(false)
                                        setErrorMessage(
                                            tran.GetText('Chyba odeslání. Zkuste to znovu.'),
                                        )
                                    }
                                }}
                            >
                                <Form>
                                    <div className="Grid gap-30 gmb-20">
                                        <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                            <Input
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                label={tran.GetText('Jméno')}
                                                placeholder={tran.GetText('Jméno')}
                                                onChange={val => {
                                                    const model = contactVal
                                                    model.firstName = val?.toString() ?? ''
                                                    setContactVal(model)
                                                }}
                                            />
                                        </FormItem>
                                        <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                            <Input
                                                id="lastName"
                                                name="lastName"
                                                type="text"
                                                label={tran.GetText('Příjmení')}
                                                placeholder={tran.GetText('Příjmení')}
                                                onChange={val => {
                                                    const model = contactVal
                                                    model.lastName = val?.toString() ?? ''
                                                    setContactVal(model)
                                                }}
                                            />
                                        </FormItem>

                                        <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                            <Input
                                                id="email"
                                                name="email"
                                                type="text"
                                                label={tran.GetText('E-mail')}
                                                placeholder={tran.GetText('E-mail')}
                                                onChange={val => {
                                                    const model = contactVal
                                                    model.email = val?.toString() ?? ''
                                                    setContactVal(model)
                                                }}
                                            />
                                        </FormItem>
                                        <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                            <Input
                                                id="phone"
                                                name="phone"
                                                type="text"
                                                inputmode="numeric"
                                                onChange={handlePhoneChange}
                                                label={tran.GetText('Telefon')}
                                                placeholder={tran.GetText('Telefon')}
                                            />
                                        </FormItem>

                                        <FormItem className="FormItem smw:w-full md:w-full lg:w-full">
                                            <Input
                                                id="message"
                                                name="message"
                                                type="text"
                                                component="textarea"
                                                label={tran.GetText('Zpráva')}
                                                placeholder={tran.GetText('Zpráva')}
                                                className="Input--textarea"
                                                onChange={val => {
                                                    const model = contactVal
                                                    model.message = val?.toString() ?? ''
                                                    setContactVal(model)
                                                }}
                                            />
                                        </FormItem>
                                        <FileUpload
                                            ref={fileUploadRef}
                                            name="demo[]"
                                            url="/api/upload"
                                            multiple
                                            accept="image/*,application/pdf"
                                            maxFileSize={15000000}
                                            invalidFileSizeMessageSummary={tran.GetText(
                                                'Překročen limit velikosti souboru.',
                                            )}
                                            invalidFileSizeMessageDetail={tran.GetText(
                                                'Velikost souboru {0} je příliš velká, maximální velikost je 15MB.',
                                            )}
                                            onUpload={onTemplateUpload}
                                            onSelect={onTemplateSelect}
                                            onError={onTemplateClear}
                                            onClear={onTemplateClear}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemTemplate}
                                            emptyTemplate={emptyTemplate}
                                            chooseOptions={chooseOptions}
                                        />
                                        <div className="FormItem FormItem--submit smw:w-12/12 md:w-6/12 lg:w-6/12">
                                            {errorMessage?.length > 0 && (
                                                <p className="InputError">
                                                    <span className=" Icon Icon--alert AlertIcon"></span>{' '}
                                                    {errorMessage}
                                                </p>
                                            )}
                                        </div>

                                        <div className="FormItem FormItem--submit smw:w-12/12 md:w-6/12 lg:w-6/12">
                                            <Button
                                                className="Button--big"
                                                variantColor="green"
                                                as="button"
                                                type="submit"
                                                disabled={disabled}
                                            >
                                                {tran.GetText('Odeslat zprávu')}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        )}
                    </Container>
                </Modal>
            </>
        )
    return <></>
}
