import { ReactElement } from 'react'
import { LoadArea } from '../../../../components/LoadArea'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../components/ToggleOpenerGeneralVoucher'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Table } from '../../../../components/Table'
import './index.styl'
import { i18n } from '../../../../translations/i18n'
import i18next from 'i18next'
import { SectionCarInsuranceAutoCheck } from '../SectionCarInsuranceAutocheck'
import { useAppContext } from '../../../../contexts/AppContext'

export const SectionCheckVehicleByPerson = ({ sourceData }: DetailPayedProps): ReactElement => {
    if (!sourceData?.otherControlResult) return <></>
    const appContext = useAppContext()
    const tran = new Translate()
    let titleRightText = tran.GetText('- 400 Kč')
    let currency = tran.GetText('Kč')
    let amountBasicPrice = '3 490'
    let amountDiscount = '- 200'
    let amountFinalPrice = '3 290'
    let buttonLink = `https://automato.cz/kontrola-auta-pred-koupi/portal/cebia/?refVehicle=${
        appContext.payedStateModel.carMake ?? ''
    }+${appContext.payedStateModel.carModel ?? ''}`
    const autobezobavLink = `https://autobezobav.${
        i18n.language
    }/cebia/?utm_source=cebia&utm_medium=affil&utm_campaign=cebia_autobezobav&autotracer=${
        sourceData.otherControlResult.couponNumber
    }&brand=${appContext.payedStateModel.carMake ?? ''}&model=${
        appContext.payedStateModel.carModel ?? ''
    }&vin=${appContext.payedStateModel.carVin ?? ''}`
    switch (i18n.language) {
        case 'sk':
            titleRightText = '- 8 EUR'
            currency = 'EUR'
            amountBasicPrice = '149,9'
            amountDiscount = '- 8'
            amountFinalPrice = '141,9'
            buttonLink = `https://www.autobazar.eu/komplexne-preverenie-vozidla?utm_medium=report&utm_source=cebia&utm_campaign=KP&brand=${
                appContext.payedStateModel.carMake ?? ''
            }&model=${appContext.payedStateModel.carModel ?? ''}&vin=${
                appContext.payedStateModel.carVin ?? ''
            }`
            break
    }
    return (
        <>
            {i18n.language == 'cz' && (
                <div className="sectionCheckVehicleByPerson">
                    <div className="Grid gmb-5">
                        <ToggleOpenerGeneralVoucher
                            href="#personAutoMotoToggle"
                            preTitleRightValue="Po celé ČR"
                            titleLeft="Sleva na fyzickou kontrolu vozu technikem od Automato.cz"
                            titleRight={titleRightText}
                            button={tran.GetText('Detail')}
                            isOpened={false}
                        />
                        <ToggleItemGeneral id="personAutoMotoToggle">
                            <div className="toggleContent">
                                <p className="text-justify">
                                    Zkušený technik vůz prověří přímo u prodejce kdekoliv po celé ČR
                                    a to jak v autobazaru, tak u soukromníka. Prověření zahrnuje
                                    fyzickou kontrolu 170 kontrolních bodů, diagnostiku, měření
                                    tloušťky laku a testovací jízdu . Získáte on-line report všech
                                    nalezených závad a doporučení, zda je vůz vhodný ke koupi. Vše
                                    již <b>do 24 hodin</b> po objednání.
                                </p>
                                <ul className="List List--triangle mb-30">
                                    <li>
                                        <div>
                                            <b>170+</b> bodů kontroly, diagnostika, testovací jízda,
                                            detailní foto i video
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Zkušený technik vůz prověří a dá vám jasná doporučení
                                        </div>
                                    </li>
                                    <li>
                                        <div>Dostanete online přehled technického stavu</div>
                                    </li>
                                    <li>
                                        <div>
                                            Po celé ČR již do <b>24 hodin</b> od objednání
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Doprava technika <b>zdarma</b>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            Sleva 400 Kč s kódem <b>CEBIA400</b>
                                        </div>
                                    </li>
                                </ul>
                                <h3>{tran.GetText('Jak uplatnit slevu?')}</h3>
                                <p className="text-justify">
                                    Při objednání kontroly vozu na Automato.cz vložte do určeného
                                    pole v košíku slevový kód <b>CEBIA400</b> a sleva vám bude
                                    automaticky odečtena z ceny.
                                </p>
                                <div className="ButtonList mb-10">
                                    <a
                                        href={buttonLink}
                                        target="_blank"
                                        className="Button Button--small"
                                        rel="noreferrer"
                                    >
                                        <span className="Button-content">
                                            {tran.GetText('Více informací')}
                                        </span>
                                    </a>
                                </div>
                                <p className="text-justify">
                                    Službu Automato neposkytuje společnost Cebia, spol. s r.o.
                                    Bližší informace o této službě naleznete na webových stránkách{' '}
                                    <a href="https://automato.cz/" target="_blank" rel="noreferrer">
                                        www.automato.cz
                                    </a>{' '}
                                    a pro případné dotazy ke službě můžete využít{' '}
                                    <a
                                        href="https://automato.cz/kontakt/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        kontaktní formulář
                                    </a>{' '}
                                    a infolinku.
                                </p>
                            </div>
                        </ToggleItemGeneral>
                        <ToggleOpenerGeneralVoucher
                            href="#personToggle"
                            preTitleRightValue="Konzultace ZDARMA"
                            titleLeft={tran.GetText(
                                'Sleva na prohlídku vozu nezávislým technikem Autobezobav.cz',
                            )}
                            titleRight={
                                i18n.language == 'cz'
                                    ? tran.GetText('- 300 Kč')
                                    : tran.GetText('- 10 EUR')
                            }
                            button={tran.GetText('Detail')}
                            isOpened={false}
                        />
                        <ToggleItemGeneral id="personToggle">
                            <div className="toggleContent">
                                <p className="text-justify">
                                    {tran.GetText(
                                        'Chcete mít co největší jistotu, že je vůz v pořádku i po technické stránce? Vyberte si ověřeného technika Autobezobav, který s Vámi zkontroluje vůz přímo na místě prodeje a jasně Vám doporučí, jestli se jedná o dobrou koupi nebo ne.',
                                    )}
                                </p>
                                <h3 className="pb-20">
                                    {tran.GetText('Jednodušší už to být nemůže:')}
                                </h3>
                                <div className="Text mb-30">
                                    <ul>
                                        <li>{tran.GetText('Předběžná konzultace ZDARMA')}</li>
                                        <li>
                                            {tran.GetText(
                                                'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <h3>{tran.GetText('Jak uplatnit slevu?')}</h3>
                                <p className="text-justify">
                                    {tran.GetText(
                                        'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
                                    )}
                                </p>
                                <div className="ButtonList mb-10">
                                    <a
                                        href={autobezobavLink}
                                        target="_blank"
                                        className="Button Button--small"
                                        rel="noreferrer"
                                    >
                                        <span className="Button-content">
                                            {tran.GetText('VYBRAT TECHNIKA')}
                                        </span>
                                    </a>
                                </div>
                                <p className="text-justify">
                                    {tran.GetHtml(
                                        'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
                                    )}
                                </p>
                            </div>
                        </ToggleItemGeneral>
                        <ToggleOpenerGeneralVoucher
                            href="#CarviciToggle"
                            className="CarviciToggle"
                            preTitleRightValue="Kontrola PROFI"
                            titleLeft="Sleva na profesionální prohlídku vozu od Carvici"
                            titleRight="- 500 Kč"
                            button={tran.GetText('Detail')}
                            isOpened={false}
                        />
                        <ToggleItemGeneral id="CarviciToggle">
                            <div className="toggleContent">
                                <p className="text-justify">
                                    <b>Kontrola vozidla s odborností</b> a vášní pro detail,{' '}
                                    <b>přednostní provedení</b>, důkladná a časově náročná kontrola
                                    vozidla, ke které patří kontrola laku, karoserie, kvalitní
                                    provedení případných oprav laku, podvozku, motoru, převodovky,
                                    interiéru a dalších částí.{' '}
                                    <b>
                                        Před a po testovací jízdě provedeme diagnostiku a lak
                                        zkontrolujeme měřákem laku.
                                    </b>{' '}
                                    Provádíme kontrolu skrytých nehod, kontrolu zda vozidlo nebylo
                                    po povodních a jiných živelných událostech.{' '}
                                    <b>Prohlédneme auto s vámi i bez vás kdekoli v celé ČR.</b> Po
                                    prohlídce obdržíte souhrn výsledku kontroly vozidla včetně foto
                                    a video v elektronické podobě a závěrečnou zprávu o stavu.
                                </p>
                                <div className="Text mb-30">
                                    <ul>
                                        <li>Odborná kontrola vozidla</li>
                                        <li>Přednostní provedení</li>
                                        <li>
                                            Kontrola skrytých oprav a kontrolu, zda není vůz po
                                            povodních
                                        </li>
                                        <li>
                                            Rozsáhlý souhrn kontroly dodaný i v elektronické podobě
                                        </li>
                                        <li>Konzultace o ceně s prodejcem</li>
                                        <li>
                                            Asistence od kontroly vozu po kontrolu dokumentů k
                                            prodeji
                                        </li>
                                        <li>
                                            Sleva s kódem <b>PROFI500</b>
                                        </li>
                                    </ul>
                                </div>
                                <h3>{tran.GetText('Jak uplatnit slevu?')}</h3>
                                <p className="text-justify">
                                    Při kliknutí na kontrolu vozidla PROFI, zadejte do rezervačního
                                    formuláře kód <b>PROFI500</b>.
                                </p>
                                <div className="ButtonList mb-10">
                                    <a
                                        href="https://www.carvici.cz/cebia-kontrola-vozidla-profi/"
                                        target="_blank"
                                        className="Button Button--small"
                                        rel="noreferrer"
                                    >
                                        <span className="Button-content">Zkontrolovat vozidlo</span>
                                    </a>
                                </div>
                            </div>
                        </ToggleItemGeneral>
                    </div>
                </div>
            )}

            {i18n.language == 'sk' && (
                <div className="sectionCheckVehicleByPerson">
                    <div className="Grid gmb-5">
                        <ToggleOpenerGeneralVoucher
                            href="#personAutoBazarToggle"
                            titleLeft="Ušetrite na opravách vďaka fyzickému prevereniu vozidla od Autobazar.EU"
                            button={tran.GetText('Detail')}
                            isOpened={false}
                        />
                        <ToggleItemGeneral id="personAutoBazarToggle">
                            <div className="toggleContent">
                                <p className="text-justify">
                                    <b>Komplexné preverenie vozidla</b> zabezpečí, že kúpa jazdenky
                                    bude rovnako bezpečná ako kúpa nového auta. Technik Autobazar.EU
                                    <b> podrobne</b> skontroluje technický stav auta a jeho
                                    históriu, čím minimalizuje riziko nečakaných problémov.
                                </p>
                                <p className="text-justify">
                                    <b>Celý proces zabezpečí náš odborný technik:</b>
                                </p>
                                <div className="Text mb-30">
                                    <ul>
                                        <li>
                                            <b>Dohodne sa s predajcom</b> na termíne kontroly a
                                            mieste stretnutia.
                                        </li>
                                        <li>
                                            <b>Preverí technický stav vozidla</b> od motora až po
                                            karosériu.
                                        </li>
                                        <li>
                                            <b>Absolvuje skúšobnú jazdu</b> a zhodnotí jazdné
                                            vlastnosti vozidla.
                                        </li>
                                        <li>
                                            <b>Zašle vám podrobný protokol</b> spolu s
                                            fotodokumentáciou a zistenými detailmi.
                                        </li>
                                    </ul>
                                </div>
                                <p className="text-justify">
                                    Potom sa už stačí len rozhodnúť, či auto kúpite alebo nie.
                                </p>
                                <div className="ButtonList mb-10">
                                    <a
                                        href={buttonLink}
                                        target="_blank"
                                        className="Button Button--small"
                                        rel="noreferrer"
                                    >
                                        <span className="Button-content">Mám záujem</span>
                                    </a>
                                </div>
                            </div>
                        </ToggleItemGeneral>
                        {/* <>
                            <ToggleOpenerGeneralVoucher
                                href="#personToggle"
                                titleLeft={tran.GetText(
                                    'Sleva na prohlídku vozu nezávislým technikem Autobezobav.cz',
                                )}
                                titleRight={tran.GetText('- 10 EUR')}
                                button={tran.GetText('Detail')}
                                isOpened={false}
                            />
                            <ToggleItemGeneral id="personToggle">
                                <div className="toggleContent">
                                    <p className="text-justify">
                                        {tran.GetText(
                                            'Chcete mít co největší jistotu, že je vůz v pořádku i po technické stránce? Vyberte si ověřeného technika Autobezobav, který s Vámi zkontroluje vůz přímo na místě prodeje a jasně Vám doporučí, jestli se jedná o dobrou koupi nebo ne.',
                                        )}
                                    </p>
                                    <h3 className="pb-20">
                                        {tran.GetText('Jednodušší už to být nemůže:')}
                                    </h3>
                                    <div className="Text mb-30">
                                        <ul>
                                            <li>{tran.GetText('Předběžná konzultace ZDARMA')}</li>
                                            <li>
                                                {tran.GetText(
                                                    'Prohlídka vozu od 1 990 Kč (dle vybraného technika)',
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                    <h3>{tran.GetText('Jak uplatnit slevu?')}</h3>
                                    <p className="text-justify">
                                        {tran.GetText(
                                            'Při poptávce Vámi vybraného technika na Autobezobav zadejte nejprve základní údaje pro předběžnou konzultaci zdarma a v druhém kroku poptávky (Fyzická prohlídka) zadejte do pole Slevový kupon číslo Vašeho kuponu AUTORACER. Při prohlídce Vám pak technik automaticky odečte 300 Kč z ceny prohlídky.',
                                        )}
                                    </p>
                                    <div className="ButtonList mb-10">
                                        <a
                                            href={`https://autobezobav.${i18n.language}/?utm_source=cebiask&utm_medium=affil&utm_campaign=cebiask_autobezobav&autotracer=${sourceData.otherControlResult.couponNumber}`}
                                            target="_blank"
                                            className="Button Button--small"
                                            rel="noreferrer"
                                        >
                                            <span className="Button-content">
                                                {tran.GetText('VYBRAT TECHNIKA')}
                                            </span>
                                        </a>
                                    </div>
                                    <p className="text-justify">
                                        {tran.GetHtml(
                                            'Službu Autobezobav neposkytuje společnost Cebia, spol. s r.o. Bližší informace o této službě naleznete na webových stránkách <a href="https://www.autobezobav.cz" target="_blank">www.autobezobav.cz</a> a pro případné dotazy ke službě můžete využít <a href="https://autobezobav.cz/kontakt/" target="_blank">kontaktního formuláře.</a>',
                                        )}
                                    </p>
                                </div>
                            </ToggleItemGeneral>
                        </> */}
                        <SectionCarInsuranceAutoCheck />
                    </div>
                </div>
            )}
        </>
    )
}
